import Mention from "@tiptap/extension-mention";
import { SolidNodeViewRenderer, SolidRenderer, getTiptapSolidReactiveOwner } from "tiptap-solid";
import { PluginKey } from "prosemirror-state";
import { useWire } from "@core/wire";
import { runWithOwner } from "solid-js";
import type { SuggestionProps } from "@tiptap/suggestion";
import { MentionNode, MentionsContainer, type MentionsProps } from "./Mentions.render";
import { insertCharacterCommand } from "../helpers";
import type { assets } from "@repo/client";
import type { CollectionSnapshot } from "@core/domains/collections/collections.types";
import { searchAssetsAndCollections } from "@core/lib/api/searchAssetsAndCollections";
import { findSuggestionMatch } from "./findSuggestionMatch";

export type MentionItemType =
  | {
      type: "asset";
      associatedCollection: string;
      data: assets.AssetSnapshot;
    }
  | {
      type: "collection";
      associatedCollection: string;
      data: CollectionSnapshot;
    };
export type MentionsSuggestionProps = SuggestionProps<MentionItemType>;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    Mention: {
      invokeMention: () => ReturnType;
    };
  }
}

export const lastQueryCheck = {
  query: "",
  results: false,
};

export const MentionsExtension = Mention.extend({
  name: "mentionNode",
  addAttributes() {
    return {
      type: "",
      associatedCollection: "",
      data: {},
    };
  },
  addNodeView() {
    return SolidNodeViewRenderer(MentionNode);
  },
  addCommands: () => ({
    invokeMention: () => insertCharacterCommand("@"),
  }),
  renderText({ node }) {
    const attrs = node.attrs as MentionItemType;
    const id = attrs.data.id;
    const name = attrs.type === "asset" ? attrs.data.originalFilename : attrs.data.label;
    return `@[${id}]"${name}"`;
  },
  renderHTML({ node }) {
    const attrs = node.attrs as MentionItemType;
    const id = attrs.data.id;
    const name = attrs.type === "asset" ? attrs.data.originalFilename : attrs.data.label;
    return ["span", {}, `@[${id}]"${name}"`];
  },
}).configure({
  suggestion: {
    pluginKey: new PluginKey("mention"),
    char: "@",
    allowSpaces: true,
    items: async ({ query, editor }): Promise<MentionItemType[]> => {
      const owner = getTiptapSolidReactiveOwner(editor);
      const wire = runWithOwner(owner, useWire);
      if (!wire) return [];

      const result = await searchAssetsAndCollections(query, wire);
      lastQueryCheck.query = query;
      lastQueryCheck.results = result.length > 0;
      return result;
    },
    findSuggestionMatch: (config) => findSuggestionMatch(config, lastQueryCheck),
    render: () => {
      let solidRenderer: SolidRenderer<MentionsProps> | undefined;
      return {
        onStart: (props: MentionsSuggestionProps) => {
          solidRenderer = new SolidRenderer(MentionsContainer, {
            props,
            editor: props.editor,
          });
          solidRenderer.updateProps(props);
        },
        onUpdate(props: MentionsSuggestionProps) {
          solidRenderer?.updateProps(props);
        },
        onExit() {
          solidRenderer?.destroy();
          solidRenderer = undefined;
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            solidRenderer?.destroy();
            solidRenderer = undefined;
            return true;
          }
          return false;
        },
      };
    },
  },
});
