import { Match, Switch } from "solid-js";
import { IconsSize, type InternalIconProps } from "@core/components/icons/types";
import { IconSVG, isSize } from "./common";

/**
 * HomeIcon component.
 * ref: home from https://heroicons.com/solid
 * @param props
 * @constructor
 */
export const HomeIcon = (props: InternalIconProps) => {
  const size = isSize(props);
  return (
    <IconSVG size={props.size} fill="currentColor">
      <Switch>
        <Match when={size(IconsSize.Micro)}>
          <path d="M8.543 2.232a.75.75 0 0 0-1.085 0l-5.25 5.5A.75.75 0 0 0 2.75 9H4v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V9h1.25a.75.75 0 0 0 .543-1.268l-5.25-5.5Z" />
        </Match>
        <Match when={size(IconsSize.Mini)}>
          <path
            fill-rule="evenodd"
            d="M9.293 2.293a1 1 0 0 1 1.414 0l7 7A1 1 0 0 1 17 11h-1v6a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6H3a1 1 0 0 1-.707-1.707l7-7Z"
            clip-rule="evenodd"
          />
        </Match>
        <Match when={size(IconsSize.Default)}>
          <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
          <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
        </Match>
      </Switch>
    </IconSVG>
  );
};
