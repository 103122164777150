import { defaultCollectionName, photoURL, type users } from "@repo/client";
import type { Named } from "@repo/logger";
import type { Auth, User } from "firebase/auth";

export const isGuestEmail = (email: string) => email.endsWith("@guest.storytell.ai");

export const prepareSyncAuthWithAPIParams = (
  user: User,
  deviceId: string,
  locale: string,
  userAgent: string,
): users.UserAuthSyncCmdParams => ({
  photoURL: user.photoURL || photoURL.DefaultGuestPhotoFQLURL,
  deviceID: deviceId,
  displayName: user.displayName || "Guest",
  email: user.email || "guest@guest.storytell.ai",
  isEmailVerified: user.emailVerified || false,
  locale: locale,
  userAgent,

  defaultOrganizationCollectionLabel: defaultCollectionName.DefaultOrganizationCollectionLabel,
  defaultPersonalCollectionLabel: defaultCollectionName.DefaultPersonalCollectionLabel,
  defaultFavoritesCollectionLabel: defaultCollectionName.DefaultFavoritesCollectionLabel,
  workflowVariants: [],

  // TODO: bring back localization
  defaultOrganizationDisplayName: "My Organization",
  defaultOrganizationName: "My Organization",
  defaultOrganizationSlug: "my-organization",
  defaultProjectDisplayName: "Default Project",
  defaultProjectName: "My Default Project",
  defaultProjectSlug: "default-project",
});

export const createTokenRefresher = (auth: Auth, setToken: (token: string) => void, logger: Named) => {
  let schedule: NodeJS.Timeout | null = null;
  const refresh = async () => {
    if (!auth.currentUser) {
      logger.warn("no user to refresh token for");
      return;
    }
    logger.info("refreshing token");
    const token = await auth.currentUser.getIdToken(true);
    setToken(token);
    schedule = null;
  };
  return {
    refresh,
    schedule: (seconds: number) => {
      schedule = setInterval(refresh, 1000 * seconds);
    },
  };
};
