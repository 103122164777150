import type { IconTypes } from "solid-icons";
import { TbLoader2 } from "solid-icons/tb";
import { Show, splitProps } from "solid-js";
import { StIcon } from "@core/components/icons";
import { classNames } from "@core/lib/classNames";
import styles from "./PrimaryCTA.module.css";
import type { CTAProps } from "./types";

interface ButtonProps extends CTAProps {
  label: string;
  icon?: IconTypes;
  size?: "small" | "medium";
  inactive?: boolean;
  loading?: boolean;
  rounded?: boolean;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
}
type Props = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;

export const PrimaryCTA = (props: Props) => {
  const [local, rest] = splitProps(props, [
    "label",
    "accessiblePrefix",
    "accessibleSuffix",
    "icon",
    "class",
    "rounded",
    "inactive",
    "loading",
    "size",
  ]);
  return (
    <button
      {...rest}
      type={props.type ?? "button"}
      class={classNames(
        styles["primary-cta"],
        local.size === "small" && styles["primary-cta--small"],
        local.rounded ? styles["primary-cta--rounded"] : "",
        local.inactive ? styles["primary-cta--inactive"] : "",
        local.class,
      )}
      tabIndex={0}
      disabled={local.inactive || local.loading}
    >
      <Show when={!local.loading && local.icon}>
        {(icon) => <StIcon icon={icon()} class={styles["primary-cta__icon"]} size="1.25rem" />}
      </Show>
      <Show when={local.loading}>
        <StIcon
          icon={TbLoader2}
          class={classNames(styles["primary-cta__icon"], styles["primary-cta__icon--spinning"])}
          size="1.25rem"
        />
      </Show>
      <Show when={local.accessiblePrefix}>
        <span class="screen-reader">{local.accessiblePrefix}</span>
      </Show>
      <span class={styles["primary-cta__label"]}>{local.label}</span>
      <Show when={local.accessibleSuffix}>
        <span class="screen-reader">{local.accessibleSuffix}</span>
      </Show>
    </button>
  );
};
