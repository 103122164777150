import { getRootCollectionUIOverloads } from "@core/domains/collections/collections.helpers";
import { usePromptContext } from "../PromptContext";
import { Switch as KSwitch } from "@kobalte/core";
import { useUIState } from "@core/ui/UIState";
import { Tooltip, TooltipTrigger } from "@core/components/_original/Tooltip";

export const ExtensionChatScope = () => {
  const { activeCollection, promptRef } = usePromptContext();
  const override = () => getRootCollectionUIOverloads(activeCollection());
  const ui = useUIState();
  const [usePublicKnowledge, setUsePublicKnowledge] = ui.usePublicKnowledge;

  return (
    <Tooltip
      mount={promptRef()}
      theme="invert"
      content={
        <>
          <p class="max-w-80">
            Include the knowledge from your <span class="font-semibold">{override()?.label}</span> in Storytell’s
            answer.{" "}
            <a
              class="text-xs text-blue-300 dark:text-blue-600"
              target="_blank"
              href="https://go.storytell.ai/chrome-extension-collection-knowledge"
              rel="noreferrer"
            >
              [Learn more]
            </a>
          </p>
        </>
      }
      placement="top"
    >
      <TooltipTrigger as="div">
        <KSwitch.Root
          class="flex items-center gap-2 cursor-pointer"
          onChange={(checked) => {
            if (checked) setUsePublicKnowledge("both");
            else setUsePublicKnowledge("public");
          }}
          checked={usePublicKnowledge() === "both"}
        >
          <KSwitch.Label class="flex items-center text-2xs select-none cursor-pointer gap-1">
            Include <span class="inline-block max-w-30 truncate">{override()?.label}</span> knowledge
          </KSwitch.Label>
          <KSwitch.Input class="peer" />
          <KSwitch.Control class="inline-flex items-center h-6 w-11 border border-surface-decoration rounded-full px-0.5 bg-surface transition-colors duration-250 data-[checked]:border-primary data-[checked]:bg-primary peer-focus:ring-2 peer-focus:ring-primary">
            <KSwitch.Thumb class="h-5 w-5 rounded-full bg-white transition-transform duration-250 data-[checked]:translate-x-[calc(100%-1px)]" />
          </KSwitch.Control>
        </KSwitch.Root>
      </TooltipTrigger>
    </Tooltip>
  );
};
