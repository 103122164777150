import { TbBulb } from "solid-icons/tb";
import { createMemo, Show } from "solid-js";
import { StButton } from "@core/components/_original/StButton";
import { ImprovePromptModal } from "@core/screens/modals/ImprovePromptModal";
import { useUIState } from "@core/ui/UIState";
import { getPromptTextAndDecorations } from "../getPromptTextAndDecorations";
import { usePromptContext } from "../PromptContext";

export const ChatPromptImproving = () => {
  const { focused, editor } = usePromptContext();
  const empty = createMemo(() => !!editor()?.isEmpty);

  const state = useUIState();
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;

  return (
    <Show when={focused() && !empty()}>
      <div class="flex justify-center animate-fade-in">
        <StButton
          class="w-auto bg-indigo-600 text-white hover:bg-indigo-600"
          size="sm"
          icon={TbBulb}
          onClick={() => {
            const { text } = getPromptTextAndDecorations(editor());
            setModalOpen("improve-prompt-modal");
            setModalContents(() => () => (
              <ImprovePromptModal
                prompt={text ?? ""}
                onClose={() => {
                  setModalOpen("");
                  setModalContents(null);
                }}
              />
            ));
          }}
        >
          Improve it
        </StButton>
      </div>
    </Show>
  );
};
