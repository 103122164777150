import { Dialog } from "@kobalte/core";
import { stAnalytics } from "@repo/analytics";
import { type Component, Match, Switch, createEffect, createSignal, on, onCleanup, onMount } from "solid-js";
import { StTabs } from "@core/components/_original/StTabs";
import { Icon, IconName } from "@core/components/icons";
import { useThreadEventProperties } from "@core/domains/analytics/useThreadEventProperties";
import { FileDropzone } from "@core/marketing/components/FileDropzone";
import { CustomEvents } from "@core/ui/custom-events";
import { usePromptContext } from "../PromptContext";
import { ChatFullScreenFileDrop } from "./ChatFullScreenFileDrop";
import { PastedContentUpload } from "./PastedContentUpload";
import { WebContentUpload } from "./WebContentUpload";
import { createWritableMemo } from "@solid-primitives/memo";

export type ChatFileUploadStrategy =
  | {
      type: "file";
    }
  | { type: "web"; assetsIds: string[] };

export const ChatFileUpload: Component<{
  open: boolean | "files" | "paste" | "web" | "youtube";
  setOpen: (val: boolean | "files" | "paste" | "web" | "youtube") => void;
  onUploaded: (strategy: ChatFileUploadStrategy) => void;
  context?: "project" | "thread";
}> = (props) => {
  const { threadEventProps } = useThreadEventProperties();
  const { activeCollection, uploader } = usePromptContext();

  const onUploaded = async (strategy: ChatFileUploadStrategy, dontClose?: boolean) => {
    props.onUploaded(strategy);
    if (!dontClose) {
      props.setOpen(false);
    }
    CustomEvents.uploadCompleted.dispatch({
      collectionId: activeCollection()?.id,
    });
  };

  onMount(() => {
    const listener = (
      e: CustomEvent<{
        source: string;
        name: string;
        size: number;
        file: File;
        collectionId: string;
        organizationId: string;
        tenantId: string;
        forThread: boolean;
      }>,
    ) => {
      props.setOpen(true);
      uploader.addAssets(
        [
          {
            source: e.detail.source,
            name: e.detail.name,
            size: e.detail.size,
            file: e.detail.file,
          },
        ],
        e.detail.collectionId,
        e.detail.organizationId,
        e.detail.tenantId,
      );

      uploader.onConfirm(e.detail.forThread);
    };

    CustomEvents.saveContentAsAsset.add(listener);

    onCleanup(() => {
      CustomEvents.saveContentAsAsset.remove(listener);
    });
  });

  createEffect(
    on(
      () => props.open,
      (open) => {
        if (open) {
          stAnalytics.track("file_upload_modal_opened", threadEventProps());
        }
      },
    ),
  );

  const [tab, setTab] = createWritableMemo(() => (typeof props.open === "string" ? props.open : "files"));

  return (
    <>
      <Dialog.Root open={!!props.open} onOpenChange={props.setOpen} modal={false} preventScroll>
        <Dialog.Portal>
          <Dialog.Overlay class="fixed z-[310] inset-0 bg-indigo-1100/50" />
          <div class="fixed inset-0 z-[310] flex items-center justify-center">
            <Dialog.Content class="bg-indigo-800 w-full max-w-3xl text-white rounded-lg p-5 animate-fade-out kb-expanded:animate-fade-in">
              <div class="flex items-center justify-between gap-5">
                <Dialog.Title class="sr-only">Add knowledge</Dialog.Title>
                <StTabs
                  theme="dark"
                  tabs={[
                    {
                      label: "Upload files",
                      value: "files",
                    },
                    {
                      label: "Paste content",
                      value: "paste",
                    },
                    {
                      label: "From a website",
                      value: "web",
                    },
                    {
                      label: "YouTube",
                      value: "youtube",
                    },
                  ]}
                  active={tab()}
                  setTab={setTab}
                />
                <Dialog.CloseButton class="flex-none w-auto text-center dialog__close-button">
                  <Icon name={IconName.Xmark} />
                </Dialog.CloseButton>
              </div>

              <Switch>
                <Match when={tab() === "files"}>
                  <div class="mt-5">
                    <FileDropzone close={() => onUploaded({ type: "file" })} context={props.context || "thread"} />
                  </div>
                </Match>

                <Match when={tab() === "paste"}>
                  <div class="mt-5">
                    <PastedContentUpload
                      context={props.context || "thread"}
                      close={() => onUploaded({ type: "file" })}
                    />
                  </div>
                </Match>

                <Match when={tab() === "web"}>
                  <div class="mt-5">
                    <WebContentUpload type="website" context={props.context || "thread"} close={onUploaded} />
                  </div>
                </Match>
                <Match when={tab() === "youtube"}>
                  <div class="mt-5">
                    <WebContentUpload type="youtube" context={props.context || "thread"} close={onUploaded} />
                  </div>
                </Match>
              </Switch>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>

      <ChatFullScreenFileDrop context={props.context || "thread"} openChatFileUpload={() => props.setOpen(true)} />
    </>
  );
};
