import { stAnalytics } from "@repo/analytics";
import { HiSolidMicrophone } from "solid-icons/hi";
import { Show, createSignal } from "solid-js";
import { Tooltip, TooltipTrigger } from "@core/components/_original/Tooltip";
import { StIcon } from "@core/components/icons";
import { useThreadEventProperties } from "@core/domains/analytics/useThreadEventProperties";
import { useSpeechToText } from "@core/lib/useSpeechToText";
import { usePromptContext } from "../PromptContext";

export const ChatMicrophone = () => {
  const { editor, submitPrompt } = usePromptContext();
  const { threadEventProps } = useThreadEventProperties();
  const [speechStart, setSpeechStart] = createSignal(0);

  const { start, stop, speaking } = useSpeechToText((text, isFinal) => {
    const e = editor();
    if (!e) return;
    e.commands.deleteRange({
      from: speechStart(),
      to: e.state.selection.$from?.pos || 0,
    });
    e.commands.insertContent({ type: "text", text });
    if (isFinal) {
      setSpeechStart(e.state.selection.$from?.pos || 0);
    }
  });

  const startSpeaking = () => {
    const e = editor();
    if (!e) return;
    setSpeechStart(e.state.selection.$from?.pos || 0);
    start();
    stAnalytics.track("prompt_speech_to_text_started", threadEventProps());
  };

  const stopSpeaking = () => {
    stop();
    stAnalytics.track("prompt_speech_to_text_stopped", threadEventProps());
  };

  return (
    <Tooltip theme="invert" placement="top" content={<div>Speak</div>}>
      <TooltipTrigger
        as="button"
        aria-label="Prompt Microphone"
        class="min-w-auto min-h-auto w-auto border-r px-4 mr-4 self-end py-2 my-2 relative dark:text-white dark:border-r-slate-700"
        classList={{
          "text-white": speaking(),
        }}
        onClick={() => {
          if (speaking()) stopSpeaking();
          else startSpeaking();
        }}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === "Enter" && speaking()) {
            stopSpeaking();
            submitPrompt();
            editor()?.commands.focus();
          }
          if (e.key !== "Tab") e.preventDefault();
        }}
      >
        <Show when={speaking()}>
          <div class="absolute top-0 left-2 h-10 w-10 rounded-full bg-red-500 after:animate-pulse" />
          <div class="absolute top-0 left-2 h-10 w-10 rounded-full bg-red-500 animate-ping" />
        </Show>

        <StIcon icon={HiSolidMicrophone} class="size-6 relative" />
      </TooltipTrigger>
    </Tooltip>
  );
};
