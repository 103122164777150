import { AssetLifecycleStates, type assets, getRequestClient } from "@repo/client";
import { CollectionKind, type CollectionSnapshot } from "@core/domains/collections/collections.types";
import type { Wire } from "../../wire";

export type AssetsAndCollectionsSearchResult = (
  | {
      type: "asset";
      associatedCollection: string;
      data: assets.AssetSnapshot;
    }
  | {
      type: "collection";
      associatedCollection: string;
      data: CollectionSnapshot;
    }
)[];

export const searchAssetsAndCollections = async (q: string, wire: Wire): Promise<AssetsAndCollectionsSearchResult> => {
  const query = q.toLowerCase();
  const client = getRequestClient(wire.services.auth.token);

  let assets: AssetsAndCollectionsSearchResult = [];
  try {
    if (query.length >= 3) {
      assets = await client.controlplane
        .SearchForAssets({
          matchName: query,
          pagination: {
            Limit: 6,
            Offset: 0,
            Sort: [],
          },
          lifecycleStates: [AssetLifecycleStates.Ready],
          filterByCollections: [],
          filterByContentType: [],
        })
        .then((r) =>
          r.data.result.entities.map(
            (a) => ({ type: "asset", data: a.data, associatedCollection: a.collectionIds?.[0] || "" }) as const,
          ),
        );
    } else {
      assets = await client.controlplane
        .RecentAssets({
          limit: 30,
          offset: 0,
          lifecycleStates: AssetLifecycleStates.Ready,
          filterByContentType: "",
          filterByAccessTypes: "",
        })
        .then((r) =>
          r.data.result.entities
            .map((a) => ({ type: "asset", data: a.data, associatedCollection: a.collectionIds?.[0] || "" }) as const)
            .filter(
              (a) =>
                a.data.originalFilename.toLowerCase().includes(query) ||
                a.data.displayName.toLowerCase().includes(query),
            )
            .slice(0, 6),
        );
    }
  } catch (error) {
    console.error(error);
  }

  const collections = wire.services.collections
    .getAllCollections()
    .filter(
      (c) =>
        c.label.replaceAll(" ", "").toLowerCase().includes(query.replaceAll(" ", "")) &&
        c.collectionKind !== CollectionKind.Favorites,
    )
    .slice(0, 6)
    .map(
      (c) =>
        ({
          type: "collection",
          data: c,
          associatedCollection: "",
        }) as const,
    );

  return [...collections, ...assets];
};
