import { type Component, Switch, Match } from "solid-js";
import { IconsSize } from "@core/components/icons";
import { ChevronRight } from "@core/components/icons/ChevronRight";
import type { SlashCommandItemType } from "../SlashCommand.types";

export const SlashCommandItem: Component<{
  index: number;
  item: SlashCommandItemType;
  focused: boolean;
  onFocus: () => void;
  parents: number[];
  onClick: () => void;
}> = (props) => {
  let ref!: HTMLButtonElement;

  return (
    <button
      ref={ref}
      type="button"
      class="w-full group outline-none rounded pl-4 py-4 pr-2 text-sm flex items-center dark:text-white"
      onFocus={() => {
        if (props.focused) return;
        props.onFocus();
      }}
      onClick={props.onClick}
      classList={{
        "bg-gray-100 dark:bg-slate-600": props.focused,
        "bg-white dark:bg-slate-700": !props.focused,
      }}
    >
      <div
        class="p-1 rounded text-gray-600 dark:text-gray-200 h-4 w-4 grid text-xs place-content-center mr-4"
        classList={{
          "bg-gray-200 dark:bg-slate-500": props.focused,
          "bg-gray-100 dark:bg-slate-600": !props.focused,
        }}
      >
        {props.index + 1}
      </div>

      <span>{props.item.label}</span>

      <span class="flex-auto" />

      <Switch>
        <Match when={props.item.subItems}>
          <div class="text-gray-700 dark:text-slate-400 px-3 w-8 grid place-content-center">
            <ChevronRight size={IconsSize.Micro} />
          </div>
        </Match>
        <Match when={props.focused}>
          <div class="text-gray-700 dark:text-slate-400 px-3 w-8 grid place-content-center">
            <Icon size={14} />
          </div>
        </Match>
        <Match when={true}>
          <div class="px-3 w-8" />
        </Match>
      </Switch>
    </button>
  );
};

const Icon = (props: { size: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-corner-down-left"
  >
    <title>Enter</title>
    <polyline points="9 10 4 15 9 20" />
    <path d="M20 4v7a4 4 0 0 1-4 4H4" />
  </svg>
);
