import { Motion } from "@repo/solid-motionone";
import type { SuggestionProps } from "@tiptap/suggestion";
import {
  type Accessor,
  For,
  type JSXElement,
  Show,
  createEffect,
  createSignal,
  on,
  onCleanup,
  onMount,
} from "solid-js";
import { VirtualElementPopup } from "@core/components/_original/VirtualElementPopup";
import { slashPopupAnimationProps } from "./SlashCommand/helpers";

const clamp = (n: number, min: number, max: number) => Math.max(min, Math.min(n, max));

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function ChatPopupMenu<T extends SuggestionProps<any>>(
  props: T & {
    children: (item: T["items"][number], index: Accessor<number>, focused: Accessor<boolean>) => JSXElement;
    onSelect?: (item: T["items"][number]) => void;
    id: string;
  },
) {
  const onClose = () => {
    props.editor.commands.keyboardShortcut("Escape");
  };
  const [focused, setFocused] = createSignal(props.items.findIndex((item) => item.type !== "label"));

  createEffect(
    on(
      () => props.items,
      () => {
        setFocused(props.items.findIndex((item) => item.type !== "label"));
      },
    ),
  );

  const onSelect = (item: T["items"][number]) => {
    props.command(item);
    props.onSelect?.(item);
  };

  onMount(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onSelect(props.items[focused()]!);
        e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
      if (e.key === "ArrowDown") {
        const next = focused() + 1 + props.items.slice(focused() + 1).findIndex((item) => item.type !== "label");
        setFocused(clamp(next, 0, props.items.length - 1));
        e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
      if (e.key === "ArrowUp") {
        const next =
          focused() -
          1 -
          props.items
            .slice(0, focused())
            .reverse()
            .findIndex((item) => item.type !== "label");
        setFocused(clamp(next, 0, props.items.length - 1));
        e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
    };
    window.addEventListener("keydown", listener, { capture: true });
    onCleanup(() => {
      window.removeEventListener("keydown", listener, { capture: true });
    });
  });

  let menuItem!: HTMLDivElement;
  createEffect(
    on(focused, (index) => {
      if (menuItem) {
        const item = menuItem.querySelector(`[data-item="${index}"]`);
        if (item) {
          item.scrollIntoView({ behavior: "instant", block: "nearest" });
        }
      }
    }),
  );

  return (
    <VirtualElementPopup
      id={props.id}
      getBoundingClientRect={() => props.clientRect?.()}
      positionUpdateTrigger={() => props.decorationNode}
      mount={document.body}
      onClose={onClose}
      class="z-max"
    >
      <Motion.div
        {...slashPopupAnimationProps}
        class="rounded-lg bg-background text-on-background border border-background-decoration overflow-hidden"
      >
        <div ref={menuItem} class="flex-col gap-2 max-h-96 overflow-y-auto">
          <For each={props.items}>
            {(item, index) => (
              <Show
                when={typeof item === "object" && "label" in item && item}
                fallback={
                  <button
                    data-item={index()}
                    type="button"
                    class="w-full group outline-none pl-2 py-2 pr-3 text-sm flex items-center min-w-[24px] min-h-[24px]"
                    classList={{
                      "bg-background-hover": index() === focused(),
                      "bg-background": index() !== focused(),
                    }}
                    onClick={() => onSelect(item)}
                  >
                    {props.children(item, index, () => focused() === index())}
                  </button>
                }
              >
                {(item) => <div class="text-xs text-on-surface-muted pl-2 py-2 pr-3">{item().label}</div>}
              </Show>
            )}
          </For>
        </div>
      </Motion.div>
    </VirtualElementPopup>
  );
}
