import { Match, Switch } from "solid-js";
import { IconsSize, type InternalIconProps } from "@core/components/icons/types";
import { IconSVG, isSize } from "./common";

export const ForwardSlashIcon = (props: InternalIconProps) => {
  const size = isSize(props);
  return (
    <IconSVG size={props.size} fill="currentColor">
      <Switch>
        {/* Only micro implementation for now (only used in the chat toolbar) */}
        <Match when={size(IconsSize.Micro)}>
          <path d="M5 13.5L11.3211 2.55159" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
        </Match>
      </Switch>
    </IconSVG>
  );
};
