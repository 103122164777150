import { type Component, Show, createMemo } from "solid-js";
import { getRequestEvent, isServer } from "solid-js/web";
import { twMerge } from "tailwind-merge";
import { useBreakpoints } from "@core/lib/useBreakPoints";
import { Tooltip, TooltipTrigger } from "./Tooltip";
const isMac = () => {
  if (isServer) {
    const event = getRequestEvent();
    return event?.request.headers.get("User-Agent")?.indexOf("Mac") !== -1;
  }
  return window.navigator.userAgent.indexOf("Mac") !== -1;
};

const normalizeKeyForPlatform = (key: string): { char: string; label: string } => {
  const lower = key.toLowerCase();
  if (["cmd", "command", "ctrl", "control"].includes(lower)) {
    return isMac() ? { char: "⌘", label: "Command" } : { char: "Ctrl", label: "Ctrl" };
  }
  if (lower === "shift") {
    return { char: "⇧", label: "Shift" };
  }
  if (lower === "enter") {
    return { char: "⏎", label: "Enter" };
  }
  if (lower === "backspace") {
    return { char: "⌫", label: "Backspace" };
  }
  if (lower === "arrowup") {
    return { char: "↑", label: "Up Arrow" };
  }
  if (lower === "arrowdown") {
    return { char: "↓", label: "Down Arrow" };
  }

  if (key.length === 1) return { label: key.toUpperCase(), char: key.toUpperCase() };
  return { char: key, label: key };
};

export const KeyboardShortcutPill: Component<{
  keys: string[];
  joiner?: string;
  class?: string;
  showOnMobile?: boolean;
}> = (props) => {
  const mapped = createMemo(() => props.keys.map(normalizeKeyForPlatform));
  const breakpoints = useBreakpoints();
  return (
    <Show when={breakpoints.sm || props.showOnMobile}>
      <Tooltip
        theme="invert"
        placement="top"
        content={
          <div>
            {mapped()
              .map((i) => i.label)
              .join(` ${props.joiner ?? "+"} `)}
          </div>
        }
      >
        <TooltipTrigger
          as="span"
          class={twMerge(
            "inline-block rounded px-2 border text-gray-500 dark:text-slate-100 dark:border-slate-700",
            props.class,
          )}
        >
          {mapped()
            .map((i) => i.char)
            .join(` ${props.joiner ?? "+"} `)}
        </TooltipTrigger>
      </Tooltip>
    </Show>
  );
};
