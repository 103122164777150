import type { Placement } from "@floating-ui/dom";
import { type Accessor, type AccessorArray, type ParentComponent, createEffect, on } from "solid-js";
import { Portal } from "solid-js/web";
import { useVirtualElementPopper } from "@core/lib/popper";
import { useClickOutside } from "@core/lib/useClickOutside";

export const VirtualElementPopup: ParentComponent<{
  getBoundingClientRect: () => DOMRect | null | undefined;
  positionUpdateTrigger: AccessorArray<unknown> | Accessor<unknown>;
  mount: HTMLElement;
  onClose: () => void;
  disableClickOutside?: boolean;
  placement?: Placement;
  class?: string;
  id?: string;
}> = (props) => {
  const { update, setPopperRef, destroy } = useVirtualElementPopper({
    placement: props.placement,
    getBoundingClientRect: props.getBoundingClientRect,
  });
  createEffect(on(props.positionUpdateTrigger, update));

  const { setRef } = useClickOutside(() => {
    if (props.disableClickOutside) return;

    props.onClose();
    destroy();
  });

  return (
    <Portal mount={props.mount}>
      <div
        id={props.id}
        class={props.class}
        ref={(el) => {
          setRef(el);
          setPopperRef(el);
        }}
      >
        {props.children}
      </div>
    </Portal>
  );
};
