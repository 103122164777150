import { KNOWLEDGE_CATEGORIES, type KnowledgeCategoryKey } from "@repo/knowledge";
import type { Component } from "solid-js";
import { twMerge } from "tailwind-merge";
import { ArrowUTurnDown } from "@core/components/icons/ArrowUTurnDown";
import { AutomateIcon } from "@core/components/icons/AutomateIcon";
import { BarsThreeIcon } from "@core/components/icons/BarsThreeIcon";
import { BookOpen } from "@core/components/icons/BookOpenDown";
import { CheckCircleIcon } from "@core/components/icons/CheckCircleIcon";
import { ChevronDown } from "@core/components/icons/ChevronDown";
import { ChevronRight } from "@core/components/icons/ChevronRight";
import { ChevronUp } from "@core/components/icons/ChevronUp";
import { ClipboardIcon } from "@core/components/icons/ClipboardIcon";
import { CogIcon } from "@core/components/icons/CogIcon";
import { CommentIcon } from "@core/components/icons/Comment";
import { DataIcon } from "@core/components/icons/DataIcon";
import { FilterIcon } from "@core/components/icons/FilterIcon";
import { HashTagIcon } from "@core/components/icons/HashTagIcon";
import { InboxIcon } from "@core/components/icons/InboxIcon";
import { KnowledgeIcon } from "@core/components/icons/KnowledgeIcon";
import { OrganizationIcon } from "@core/components/icons/OrganizationIcon";
import { PaperAirplane } from "@core/components/icons/PaperAirplaneDown";
import { PlayIcon } from "@core/components/icons/PlayIcon";
import { PlusIcon } from "@core/components/icons/PlusIcon";
import { ShareIcon } from "@core/components/icons/ShareIcon";
import { SparklesIcon } from "@core/components/icons/SparklesIcon";
import { SquaresIcon } from "@core/components/icons/SquaresDown";
import { StarIconEmpty, StarIconFilled } from "@core/components/icons/StarIconEmpty";
import { TagIcon } from "@core/components/icons/TagIcon";
import { ThumbsDownIcon } from "@core/components/icons/ThumbsDownIcon";
import { ThumbsUpIcon } from "@core/components/icons/ThumbsUpIcon";
import { UserIcon } from "@core/components/icons/UserIcon";
import { WorldIcon } from "@core/components/icons/WorldIcon";
import { XMarkIcon } from "@core/components/icons/XMarkIcon";
import type { IconsSize } from "@core/components/icons/types";
import { ArrowPathIcon } from "./ArrowPathIcon";
import { AtIcon } from "./AtIcon";
import { ForwardSlashIcon } from "./ForwardSlashIcon";
import { HomeIcon } from "./HomeIcon";
import { MicrophoneIcon } from "./MicrophoneIcon";
import { MinusIcon } from "./MinusIcon";
import { PaperclipIcon } from "./PaperclipIcon";
import { TextFormatIcon } from "./TextFormatIcon";
import { DownloadIcon } from "@core/components/icons/DownloadIcon";

// biome-ignore lint/style/useEnumInitializers: ephemeral
export enum IconName {
  AtSymbol,
  ArrowPath,
  ArrowUTurnDown,
  Automation,
  BarsThree,
  BookOpen,
  CheckCircle,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Clipboard,
  Cog,
  Comment,
  Data,
  Download,
  Filter,
  ForwardSlash,
  HashTag,
  Home,
  Inbox,
  Knowledge,
  Microphone,
  Minus,
  Organization,
  PaperAirplane,
  Paperclip,
  Play,
  Plus,
  Share,
  Sparkles,
  Squares,
  StarEmpty,
  StarFilled,
  Tag,
  TextFormat,
  ThumbsDown,
  ThumbsUp,
  User,
  World,
  Xmark,
}

const icons: Record<IconName, Component> = {
  [IconName.AtSymbol]: AtIcon,
  [IconName.ArrowUTurnDown]: ArrowUTurnDown,
  [IconName.ArrowPath]: ArrowPathIcon,
  [IconName.Automation]: AutomateIcon,
  [IconName.BarsThree]: BarsThreeIcon,
  [IconName.BookOpen]: BookOpen,
  [IconName.CheckCircle]: CheckCircleIcon,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ChevronRight]: ChevronRight,
  [IconName.ChevronUp]: ChevronUp,
  [IconName.Clipboard]: ClipboardIcon,
  [IconName.Download]: DownloadIcon,
  [IconName.HashTag]: HashTagIcon,
  [IconName.Home]: HomeIcon,
  [IconName.Cog]: CogIcon,
  [IconName.Comment]: CommentIcon,
  [IconName.Data]: DataIcon,
  [IconName.Filter]: FilterIcon,
  [IconName.ForwardSlash]: ForwardSlashIcon,
  [IconName.Inbox]: InboxIcon,
  [IconName.Knowledge]: KnowledgeIcon,
  [IconName.Microphone]: MicrophoneIcon,
  [IconName.Minus]: MinusIcon,
  [IconName.Organization]: OrganizationIcon,
  [IconName.PaperAirplane]: PaperAirplane,
  [IconName.Paperclip]: PaperclipIcon,
  [IconName.Play]: PlayIcon,
  [IconName.Plus]: PlusIcon,
  [IconName.Share]: ShareIcon,
  [IconName.Sparkles]: SparklesIcon,
  [IconName.Squares]: SquaresIcon,
  [IconName.StarEmpty]: StarIconEmpty,
  [IconName.StarFilled]: StarIconFilled,
  [IconName.Tag]: TagIcon,
  [IconName.TextFormat]: TextFormatIcon,
  [IconName.ThumbsDown]: ThumbsDownIcon,
  [IconName.ThumbsUp]: ThumbsUpIcon,
  [IconName.User]: UserIcon,
  [IconName.World]: WorldIcon,
  [IconName.Xmark]: XMarkIcon,
};

export type IconProps = {
  iconName: IconName;
  iconSize?: IconsSize;
};

export const Icon: Component<{
  name: IconName;
  size?: IconsSize;
  class?: string;
  classList?: Record<string, boolean>;
}> = (props) => {
  const IconComponent = icons[props.name];
  if (!IconComponent) {
    console.error(`Icon "${props.name}" not found.`);
    return null;
  }
  return (
    <span class={twMerge("", props.class)} classList={props.classList}>
      {/* @ts-ignore */}
      <IconComponent size={props.size} />
    </span>
  );
};

export const getIconNameForKnowledge = (kind: KnowledgeCategoryKey): IconName => {
  switch (kind) {
    case KNOWLEDGE_CATEGORIES.ALL:
      return IconName.Knowledge;
    case KNOWLEDGE_CATEGORIES.ORGANIZATION:
      return IconName.Organization;
    case KNOWLEDGE_CATEGORIES.USER:
      return IconName.User;
    case KNOWLEDGE_CATEGORIES.SPACE:
      return IconName.BookOpen;
    case KNOWLEDGE_CATEGORIES.WORLD:
      return IconName.World;
    default:
      return IconName.World;
  }
};
