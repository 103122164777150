import { Motion } from "@repo/solid-motionone";
import { createElementSize } from "@solid-primitives/resize-observer";
import type { Editor, JSONContent } from "@tiptap/core";
import { type Accessor, Show, createEffect, createMemo, onCleanup, onMount } from "solid-js";
import { twMerge } from "tailwind-merge";
import { EditorContent } from "tiptap-solid";
import type { CollectionSnapshot } from "@core/domains/collections/collections.types";
import { useClickOutside } from "@core/lib/useClickOutside";
import { useWire } from "@core/wire";
import { ChatTips } from "./ChatTips";
import { usePromptContext } from "./PromptContext";
import { ChatBackdrop } from "./components/ChatBackdrop";
import { ChatBottomBar } from "./components/ChatBottomBar";
import { ChatMicrophone } from "./components/ChatMicrophone";
import { ChatSendButton } from "./components/ChatSendButton";
import { ChatTopBar } from "./components/ChatTopBar";
import { EditorFocusedAnimation } from "./components/EditorFocusedAnimation";
import { StButton } from "@core/components/_original/StButton";
import { TbBrandYoutube, TbClipboard, TbFileUpload, TbLink } from "solid-icons/tb";

export type PromptProps = {
  disableSubmit?: boolean;
  positioning?: string;
  alwaysFocused?: boolean;
  activeCollection: Accessor<CollectionSnapshot | undefined>;
  hideToolbars?: boolean;
  autoFocusInput?: boolean;
  hideBackdrop?: boolean;
  initialPrompt?: {
    content: string | JSONContent;
    highlight: boolean;
    delay?: number;
  };
  transformationID?: string;
  onCleanup?: (editor: Editor | null) => void;
  overrideOnFilesUploaded?: () => void;
  inBackground?: boolean;

  /**
   * @deprecated Used temporarily for the chrome extension to inject the current page html
   */
  getExtraPromptContent?: () => {
    content: string;
    name: string;
    source: string;
    kind: string;
  };
};

export const PersistentPrompt = (props: {
  class?: string;
  dark?: boolean;
}) => {
  const { editor, focused, setFocused, promptRef, setPromptRef, rawProps, setShowUploadModal } = usePromptContext();
  const textBoxFocused = createMemo(() => !!editor()?.isFocused);
  const empty = createMemo(() => !!editor()?.isEmpty);
  createEffect(() => {
    if (textBoxFocused()) setFocused(true);
  });

  const { setRef } = useClickOutside(() => {
    if (focused()) setFocused(false);
  }, true);

  const onFocusChange = (event: FocusEvent) => {
    if (
      event.target instanceof Element &&
      (promptRef()?.contains(event.target) || document.getElementById("mentions-context-menu")?.contains(event.target))
    ) {
      if (!focused()) setFocused(true);
    } else {
      if (focused()) setFocused(false);
    }
  };

  const wire = useWire();

  onMount(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "k" && (event.metaKey || event.ctrlKey) && !textBoxFocused()) {
        editor()?.commands.focus();
      }
      if (event.key === "Escape" && focused()) {
        editor()?.commands.blur();
        setFocused(false);
      }
    };
    const onKeyUp = (event: KeyboardEvent) => {
      // Prompt input history (ENG-1445)
      wire.services.inputHistory.keyboardHandler(event, editor(), focused());
    };

    document.body.addEventListener("focus", onFocusChange, { capture: true });
    window.addEventListener("keyup", onKeyUp, { capture: true });
    window.addEventListener("keydown", onKeyDown, { capture: true });
    onCleanup(() => {
      document.body.removeEventListener("focus", onFocusChange, {
        capture: true,
      });
      window.removeEventListener("keyup", onKeyUp, { capture: true });
      window.removeEventListener("keydown", onKeyDown, { capture: true });
    });
  });

  return (
    <div
      class={twMerge("flex justify-center z-10", wire.isApp() && "px-8 pb-4 mb-14", props.class)}
      classList={{
        [rawProps.positioning || ""]: !!rawProps.positioning,
        "fixed bottom-0 left-0 right-0": !rawProps.positioning,
        "pt-2": !wire.services.limiting.guest.isInteractionAllowed(),
      }}
      data-testid="prompt-container"
    >
      <div class="w-full max-w-[68.75rem] px-0 pointer-events-auto">
        <div
          ref={(el) => {
            setPromptRef(el);
            setRef(el);
          }}
          class="relative rounded bg-white dark:bg-slate-950 border dark:border-white/10 shadow-xl"
        >
          <Show when={!rawProps.hideToolbars}>
            <ChatTopBar />
          </Show>

          <div class="flex items-center w-full overflow-x-hidden">
            <ChatMicrophone />

            <EditorContent
              data-prompt-container
              class={twMerge("text-base font-light flex-auto cursor-text p-4 mr-2 px-0 pr-0 dark:text-white")}
              onClick={(e) => {
                if (e.currentTarget === e.target) {
                  editor()?.commands.focus("all");
                }
              }}
              editor={editor()}
            />

            <ChatTips />

            <ChatSendButton />
          </div>

          <Show when={!rawProps.hideToolbars}>
            <ChatBottomBar />
          </Show>

          <EditorFocusedAnimation focused={focused()} empty={empty()} />
        </div>

        <div class="flex flex-wrap items-center justify-center gap-3 mt-5 mb-1" classList={{ dark: props.dark }}>
          <span class="text-on-surface">Add assets:</span>

          <StButton icon={TbFileUpload} onClick={() => setShowUploadModal("files")} class="w-auto">
            Files
          </StButton>
          <StButton icon={TbClipboard} onClick={() => setShowUploadModal("paste")} class="w-auto">
            Paste Content
          </StButton>
          <StButton icon={TbLink} onClick={() => setShowUploadModal("web")} class="w-auto">
            URLs
          </StButton>
          <StButton icon={TbBrandYoutube} onClick={() => setShowUploadModal("youtube")} class="w-auto">
            YouTube
          </StButton>
        </div>
      </div>
    </div>
  );
};
