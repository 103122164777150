import type { operation } from "./encore.gen";

type Config = {
  retries: number;
  delay: number;
  timeout: number;
};
type ConfigOpt = (cfg: Config) => void;

export const withBackoffRetry = (retries: number, delay: number): ConfigOpt => {
  return (cfg: Config) => {
    cfg.retries = retries;
    cfg.delay = delay;
  };
};

export const withTimeout = (timeout: number): ConfigOpt => {
  return (cfg: Config) => {
    cfg.timeout = timeout;
  };
};

const applyOptions = (options: ConfigOpt[]): Config => {
  const cfg = {
    retries: 0,
    delay: 0,
    timeout: 0,
  };
  for (const option of options) {
    option(cfg);
  }
  return cfg;
};

const failedOperation = <T>(message: string, startAt: number): operation.Response<T> => {
  const duration = performance.now() - startAt;
  const data: T = null as T;
  const consumption: operation.Consumption = { units: 0 };
  // @ts-expect-error
  return {
    OperationID: "_",
    Duration: `${duration}ms`,
    code: "unknown",
    message: message,
    details: {
      operation: {
        operationId: "",
        duration: duration,
      },
      consumption,
    },
    data,
  };
};

export const WithClientDefaults = (): ConfigOpt => {
  return (cfg: Config) => {
    withBackoffRetry(3, 1000)(cfg);
    withTimeout(5000)(cfg);
  };
}

export const call = <T>(
  fn: () => Promise<operation.Response<T>>,
  ...options: ConfigOpt[]
): Promise<operation.Response<T>> => {
  const startAt = performance.now();
  const cfg = applyOptions([WithClientDefaults, ...options]);
  let attemptCount = 1;

  const operationPromise = new Promise<operation.Response<T>>((resolve, reject) => {
    const retry = () => {
      attemptCount++;
      if (attemptCount > cfg.retries + 1) {
        resolve(failedOperation(`failed after ${cfg.retries} retries`, startAt));
      } else {
        setTimeout(() => {
          fn().then(resolve).catch(retry);
        }, cfg.delay * attemptCount);
      }
    };

    fn().then(resolve).catch(retry);
  });

  if (cfg.timeout > 0) {
    const timeoutPromise = new Promise<operation.Response<T>>((resolve) => {
      setTimeout(() => {
        resolve(failedOperation(`Client terminated operation after waiting  ${cfg.timeout}ms timed out`, startAt));
      }, cfg.timeout);
    });

    return Promise.race([operationPromise, timeoutPromise]);
  }

  return operationPromise;
};
