import { IoClose } from "solid-icons/io";
import { type ParentProps, createEffect } from "solid-js";
import { Dynamic, Portal } from "solid-js/web";
import { IconCTA } from "@core/components/cta/IconCTA";
import { classNames } from "@core/lib/classNames";
import { useUIState } from "@core/ui/UIState";
import { GLOBAL_ELEMENT_IDS } from "@core/ui/global-element-ids";
import styles from "./CollectionsModal.module.css";

interface Props extends ParentProps {
  class?: string;
  mount?: HTMLElement;
  accessibleLabel: string;
}

export type CollectionsModalProps = {
  id: string;
};

export const CollectionsModal = (props: Props) => {
  const state = useUIState();
  const [getContent, setContent] = state.modalContents;
  const [open, setOpen] = state.modal;
  const [rightDrawer, setRightDrawer] = state.rightDrawer;

  createEffect((previous?: string) => {
    if (open() !== "" && previous !== open()) {
      document.getElementById(GLOBAL_ELEMENT_IDS.modal)?.focus();
    }
    setRightDrawer(false);
    return open();
  });

  const close = () => {
    setOpen("");
    setContent(null);
  };

  const escapeListener = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      close();
    }
  };

  return (
    <Portal mount={props.mount ?? document.getElementById(GLOBAL_ELEMENT_IDS.portal) ?? document.body}>
      <div
        id={GLOBAL_ELEMENT_IDS.modal}
        class={classNames(
          styles["collections-modal"],
          open() !== "" ? styles["collections-modal--open"] : "",
          props.class,
        )}
        aria-hidden={open() === ""}
        aria-label={props.accessibleLabel}
        role="dialog"
        tabIndex={open() !== "" ? 0 : -1}
      >
        <div class={styles["collections-modal__backdrop"]} onClick={close} onKeyDown={escapeListener} />
        <div class={styles["collections-modal__container"]}>
          <section class={styles["collections-modal__content"]}>
            <Dynamic component={getContent() ?? undefined} />
          </section>
          <div class={styles["collections-modal__actions"]}>
            <IconCTA
              data-test-id="modal-close"
              aria-controls={GLOBAL_ELEMENT_IDS.modal}
              aria-expanded={open()}
              class={styles["collections-modal__close"]}
              onClick={close}
              accessibleLabel="Close the overlay."
              icon={IoClose}
              modifiers={["small"]}
              tabIndex={open() !== "" ? 0 : -1}
            />
          </div>
        </div>
      </div>
    </Portal>
  );
};
