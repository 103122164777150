import { Match, Show, Switch, type Component } from "solid-js";
import type { MentionItemType, MentionsSuggestionProps } from "./Mentions.extension";
import { NodeViewWrapper } from "tiptap-solid";
import type { NodeViewProps } from "@tiptap/core";
import { ChatPopupMenu } from "../ChatPopupMenu";
import { getRootCollectionUIOverloads } from "@core/domains/collections/collections.helpers";
import { StIcon } from "@core/components/icons";
import { type FileTypeIcon, useFileTypeIcon } from "@core/ui/useFileTypeIcon";
import type { CollectionSnapshot } from "@core/domains/collections/collections.types";
import { Dynamic } from "solid-js/web";
import { originalFilenameToMimeType } from "@core/lib/originalFilenameToMimeType";
import { twMerge } from "tailwind-merge";
import { useWire } from "@core/wire";
import { CollectionBreadcrumbs } from "@core/components/CollectionBreadcrumbs";

export type MentionsProps = MentionsSuggestionProps;

export const MentionsContainer: Component<MentionsProps> = (props) => {
  const grouped = () => {
    const collections = props.items.filter((item) => item.type === "collection");
    const assets = props.items.filter((item) => item.type === "asset");

    const result = [
      ...(collections.length > 0 ? ([{ label: "Collections", type: "label" }] as const) : []),
      ...collections,
      ...(assets.length > 0 ? ([{ label: "Assets", type: "label" }] as const) : []),
      ...assets,
    ];

    if (result.length === 0) return [{ label: "No assets or collections were found.", type: "label" } as const];

    return result;
  };
  return (
    <ChatPopupMenu {...props} items={grouped()} id="mentions-context-menu">
      {(item, index, focused) => <MentionItem item={item} index={index()} focused={focused()} />}
    </ChatPopupMenu>
  );
};

const MentionItem: Component<{
  item: MentionItemType | { label: string; type: "label" };
  index: number;
  focused: boolean;
}> = (props) => {
  const wire = useWire();
  const rootOverride = () =>
    props.item.type === "collection" ? getRootCollectionUIOverloads(props.item.data) : undefined;
  const iconTheme = () =>
    props.item.type === "asset"
      ? useFileTypeIcon(originalFilenameToMimeType(props.item.data.originalFilename) as FileTypeIcon)
      : undefined;

  const collection = () =>
    props.item.type === "asset"
      ? getRootCollectionUIOverloads(wire.services.collections.getCollection(props.item.associatedCollection))
      : undefined;

  return (
    <span class="rounded text-base px-2 text-on-background inline-flex items-center gap-2 select-none cursor-pointer">
      <Switch>
        <Match when={props.item.type === "label"}>{null}</Match>
        <Match when={rootOverride()}>
          {(data) => (
            <>
              <div class="flex items-center gap-2">
                <StIcon icon={data().icon} size={20} />
                <div class="flex flex-col gap-2">
                  <span class={twMerge("ml-2 truncate font-medium", props.focused && "font-medium")}>
                    {data().label || ((props.item as MentionItemType).data as CollectionSnapshot).label}
                  </span>

                  <span class="[&_a]:min-h-6 [&_a]:min-w-max [&_span]:min-h-6 [&_span]:min-w-max [&_*]:!font-normal opacity-60 text-sm ml-2 pointer-events-none">
                    <CollectionBreadcrumbs
                      noNavigation
                      max={5}
                      breadcrumbs={
                        wire.services.collections.getBreadcrumbs(
                          props.item.type === "collection" ? props.item.data.id : "",
                        ) || []
                      }
                    />
                  </span>
                </div>
              </div>
            </>
          )}
        </Match>

        <Match when={props.item.type === "asset" && props.item.data}>
          {(asset) => (
            <>
              <Dynamic component={iconTheme()?.icon} size={16} class="ml-[1px]" />
              <span class="flex flex-col ml-2 max-w-80 truncate">
                <span class={twMerge("truncate font-medium", props.focused && "font-medium")}>
                  {asset().originalFilename}
                </span>
                <Show when={asset().displayName}>
                  <span class="text-sm text-on-background leading-tight truncate">{asset().displayName}</span>
                </Show>
                <Show when={collection()}>
                  {(collection) => (
                    <span class="text-xs text-on-background opacity-50 leading-tight truncate flex items-center gap-0.5 mt-1">
                      <StIcon icon={collection().icon} size={12} />
                      {collection().label}
                    </span>
                  )}
                </Show>
              </span>
            </>
          )}
        </Match>
      </Switch>
    </span>
  );
};

export const MentionNode: Component<
  NodeViewProps & {
    node: {
      attrs: MentionItemType;
    };
  }
> = (props) => {
  const rootOverride = () =>
    props.node.attrs.type === "collection" ? getRootCollectionUIOverloads(props.node.attrs.data) : undefined;
  const iconTheme = () =>
    props.node.attrs.type === "asset"
      ? useFileTypeIcon(originalFilenameToMimeType(props.node.attrs.data.originalFilename) as FileTypeIcon)
      : undefined;

  return (
    <NodeViewWrapper as="span">
      <span
        class={twMerge(
          "dark rounded px-2 text-on-primary inline-flex items-center gap-2 select-none cursor-pointer",
          rootOverride() ? "bg-primary" : "bg-secondary",
        )}
      >
        <Switch>
          <Match when={rootOverride()}>
            {(data) => (
              <>
                <StIcon icon={data().icon} />
                <span>{data().label || (props.node.attrs.data as CollectionSnapshot).label}</span>
              </>
            )}
          </Match>

          <Match when={props.node.attrs.type === "asset" && props.node.attrs.data}>
            {(asset) => (
              <>
                <Dynamic component={iconTheme()?.icon} size="1rem" />
                <span>{asset().displayName || asset().originalFilename}</span>
              </>
            )}
          </Match>
        </Switch>
      </span>

      {/* TODO: Bring back hover card */}
      {/* <HoverCard.Root placement="top" openDelay={250} closeDelay={250}>
        <HoverCard.Trigger
          as="span"
          class="dark rounded px-2 bg-primary text-on-primary inline-flex items-center gap-2 select-none cursor-pointer"
        >
          <Switch>
            <Match when={rootOverride()}>
              {(data) => (
                <>
                  <StIcon icon={data().icon} />
                  <span>{data().label || (props.node.attrs.data as CollectionSnapshot).label}</span>
                </>
              )}
            </Match>

            <Match when={props.node.attrs.type === "asset" && props.node.attrs.data}>
              {(asset) => (
                <>
                  <Dynamic component={iconTheme()?.icon} size="1rem" />
                  <span>{asset().displayName || asset().originalFilename}</span>
                </>
              )}
            </Match>
          </Switch>
        </HoverCard.Trigger>
        <HoverCard.Portal>
          <HoverCard.Content class="flex items-center gap-3 p-4 bg-white dark:bg-slate-800 text-gray-800 dark:text-slate-100 rounded border dark:border-slate-500 shadow-lg animate-fade-out kb-expanded:animate-fade-in">
            <HoverCard.Arrow />

            <img
              src={props.node.attrs.avatar}
              alt={`Avatar of ${props.node.attrs.name}`}
              class="rounded-full h-10 w-10 border"
            />
            <a href="/" class="text-base underline text-blue-700">
              {props.node.attrs.name}
            </a>
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root> */}
    </NodeViewWrapper>
  );
};
