import {
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
  type VirtualElement,
  type Placement,
} from "@floating-ui/dom";
import { createSignal, onCleanup, onMount } from "solid-js";

/**
 * Creates a popper pop-up using a virtual element with a stub of the getBoundingClientRect.
 * Used a performance optimization for the SlashCommand pop-up since the target DOM element for the
 * pop-up changes with each editor update. We would have to create a new popper instance for each new DOM element.
 * Using the bouding client rect we can just call instance().update() when it needs to recalculate position.
 */
export const useVirtualElementPopper = ({
  getBoundingClientRect,
  placement,
}: {
  getBoundingClientRect: () => DOMRect | undefined | null;
  placement?: Placement;
}) => {
  const [ref, setRef] = createSignal<HTMLElement>();
  const virtualElement: VirtualElement = {
    getBoundingClientRect: () =>
      getBoundingClientRect?.() || {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        toJSON() {},
      },
  };

  const updatePosition = () => {
    const el = ref();
    if (!el) return;

    computePosition(virtualElement, el, {
      placement: placement ?? "right-end",
      middleware: [
        shift(),
        flip({
          fallbackPlacements: ["top", "bottom", "right", "left"],
        }),
        offset({
          mainAxis: 30,
          crossAxis: 30,
        }),
      ],
    }).then((p) => {
      Object.assign(el.style, {
        position: "absolute",
        left: `${p.x}px`,
        top: `${p.y}px`,
      });
    });
  };

  let cleanup: () => void = () => {};
  onMount(() => {
    const el = ref();
    if (!el) return;

    cleanup = autoUpdate(virtualElement, el, updatePosition);

    onCleanup(() => {
      cleanup();
    });
  });

  return {
    update: updatePosition,
    setPopperRef: setRef,
    destroy: cleanup,
  };
};
