import { type Component, For, onCleanup, onMount } from "solid-js";
import { Motion } from "@repo/solid-motionone";
import { VirtualElementPopup } from "@core/components/_original/VirtualElementPopup";
import { SlashCommandItem } from "./components/SlashCommandItem";
import { slashPopupAnimationProps } from "./helpers";
import type { SlashCommandSuggestionProps } from "./SlashCommand.extension";
import { SlashMenuProvider, useSlashMenuContext } from "./SlashMenuContext";

export type SlashMenuProps = SlashCommandSuggestionProps;

export const SlashMenuContainer: Component<SlashMenuProps> = (props) => {
  return (
    <SlashMenuProvider {...props}>
      <SlashMenu />
    </SlashMenuProvider>
  );
};

export const SlashMenu = () => {
  const {
    goNext,
    goPrevious,
    goChild,
    goParent,
    setActive,
    isActive,
    onClose,
    rawProps,
    selectActiveItem,
    selectItem,
  } = useSlashMenuContext();

  onMount(() => {
    const mapper = {
      ArrowUp: goPrevious,
      ArrowDown: goNext,
      ArrowLeft: goParent,
      ArrowRight: goChild,
      Escape: onClose,
      Enter: selectActiveItem,
    };
    const listener = (e: KeyboardEvent) => {
      if (Object.keys(mapper).includes(e.key)) {
        mapper[e.key as keyof typeof mapper]();
        e.preventDefault();
        return;
      }

      if (["1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(e.key)) {
        setActive(Number(e.key) - 1);
        e.preventDefault();
        return;
      }
    };
    window.addEventListener("keydown", listener, { capture: true });
    onCleanup(() => {
      window.removeEventListener("keydown", listener, { capture: true });
    });
  });

  return (
    <VirtualElementPopup
      getBoundingClientRect={() => rawProps.clientRect?.()}
      positionUpdateTrigger={() => rawProps.decorationNode}
      // Targeting the chat prompt text box so that the commands are next in dom after the content-editable
      // (makes it easier for tab-based keyboard nav since the commands are next in line)
      mount={document.getElementById("chat-prompt-text-box") as HTMLElement}
      onClose={onClose}
      class="z-10"
    >
      <Motion.div
        {...slashPopupAnimationProps}
        class="bg-white dark:bg-slate-700 rounded border dark:border-slate-500 shadow-lg"
      >
        <div class="flex-col gap-2 min-w-72 max-h-96 overflow-y-auto">
          <For each={rawProps.items}>
            {(item, index) => (
              <SlashCommandItem
                parents={[]}
                index={index()}
                item={item}
                focused={isActive(index(), [])}
                onFocus={() => setActive(index())}
                onClick={() => selectItem(index(), [])}
              />
            )}
          </For>
        </div>
      </Motion.div>
    </VirtualElementPopup>
  );
};
