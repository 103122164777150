import { Typography } from "@tiptap/extension-typography";
import StarterKit from "@tiptap/starter-kit";
import { ChatSettingsExtension } from "./ChatSettings.extension";
import { Placeholder } from "./Placeholder.extension";
import { SlashCommandExtension } from "./SlashCommand/SlashCommand.extension";
import { VariablesExtension } from "./Variables/Variables.extension";
import { MentionsExtension } from "./Mentions/Mentions.extension";
import { CollectionMentionParser } from "./Mentions/MentionsParser.extension";

export const getEditorExtensions = () => [
  // Mark starter kit extensions as false to disable them
  // We can gradually enable some more if we want to support rich text
  StarterKit.configure({
    blockquote: false,
    // bold: false,
    code: false,
    codeBlock: false,
    hardBreak: false,
    heading: false,
    horizontalRule: false,
    italic: false,
    // bulletList: false,
    // listItem: false,
    // orderedList: false,
    bulletList: {
      HTMLAttributes: {
        class: "!list-disc ml-5",
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: "!list-decimal ml-5",
      },
    },
    strike: false,
  }),
  Typography,
  ChatSettingsExtension,
  SlashCommandExtension,
  CollectionMentionParser,
  MentionsExtension,
  // TagsExtension,
  Placeholder.configure({
    emptyEditorClass:
      "before:content-[attr(data-placeholder)] before:float-left before:h-0 before:text-gray-500 before:dark:text-gray-400-dark before:pointer-events-none",
    placeholder: "What insights would you like? Just ask Storytell to get started.",
  }),
  VariablesExtension,
];
