import { APIError, getRequestClient } from "@repo/client";
import { captureException } from "@repo/observability";
import { TbQuestionMark, TbTrash } from "solid-icons/tb";
import { type Component, For, Show, batch, createEffect, createSignal, on } from "solid-js";
import { StButton } from "@core/components/_original/StButton";
import { StIcon } from "@core/components/icons";
import { useWire } from "@core/wire";
import { usePromptContext } from "../PromptContext";
import type { ChatFileUploadStrategy } from "./ChatFileUpload";
import { createStore } from "solid-js/store";
import { PrimaryCTA } from "@core/components/cta/PrimaryCTA";
import { IconCTA } from "@core/components/cta/IconCTA";

export const WebContentUpload: Component<{
  context: "thread" | "project";
  type: "youtube" | "website";
  close: (strategy: ChatFileUploadStrategy, dontClose?: boolean) => void;
}> = (props) => {
  const wire = useWire();
  const { activeCollection } = usePromptContext();
  const [error, setError] = createSignal<string | null>(null);
  const client = getRequestClient(wire.services.auth.token);

  let formRef!: HTMLFormElement;

  const [state, setState] = createStore({
    urls: [""],
    errors: {} as Record<number, string | undefined>,
    count: 1,
  });

  const showAddMore = () => {
    if (props.type === "youtube" || state.count >= 10 || state.urls.at(-1)?.length === 0) {
      return false;
    }
    return true;
  };

  const [loading, setLoading] = createSignal(false);

  const isValidUrl = (url: string) => {
    try {
      // Add https:// if no protocol specified
      const urlToCheck = url.match(/^https?:\/\//) ? url : `https://${url}`;
      const urlObj = new URL(urlToCheck);
      // Check that hostname exists and has at least one dot
      if (!urlObj.hostname || urlObj.hostname.length < 1 || !urlObj.hostname.includes(".")) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  };

  const uploadPastedContent = async (e: SubmitEvent) => {
    e.preventDefault();
    const { urls } = state;

    setLoading(true);

    const nonEmpty = urls.filter((url) => url.length > 0);
    if (nonEmpty.length === 0) {
      setState("errors", 0, "Please enter a URL");
      setLoading(false);
      return;
    }

    for (const [i, url] of nonEmpty.entries()) {
      if (!isValidUrl(url)) {
        setState("errors", i, "Please enter a valid URL");
        setLoading(false);
        return;
      }
      if (nonEmpty.slice(i + 1).includes(url)) {
        setState("errors", i, "Please enter a unique URL");
        setLoading(false);
        return;
      }
    }

    try {
      const col = activeCollection();
      if (!col) return;
      const results = await Promise.allSettled(
        nonEmpty.map(async (url) => {
          return client.controlplane.CrawlURL({
            url,
            collectionID: col.id,
            organizationID: col.organizationId,
            tenantID: col.tenantId,
            processInThread: props.context === "thread",
            processInBackground: props.context === "project",
          });
        }),
      );

      const success = results.filter((r) => r.status === "fulfilled");
      const failure = results.filter((r) => r.status === "rejected");

      const allPassed = failure.length === 0;
      const allFailed = success.length === 0;

      if (allPassed) {
        props.close({ type: "web", assetsIds: success.map((r) => r.value.data.id) });
        return;
      }
      if (allFailed) {
        setError("Something went wrong. Please try again.");
        results.forEach((r, i) => {
          const reason = r.status === "rejected" && r.reason instanceof APIError ? r.reason.message : undefined;
          if (!reason) return;
          setState("errors", i, reason);
        });
        captureException(results.map((r) => (r.status === "rejected" ? r.reason : undefined)).filter(Boolean));
        setLoading(false);
        return;
      }

      // Handle case for some succeeding and some failing scrapes
      props.close(
        { type: "web", assetsIds: success.map((r) => r.value.data.id) },
        // Don't close the modal if there are failures, we need to let the user know
        true,
      );

      setError(
        "Some links failed to scrape. Please check them and try again.\nThe successful links have already been added to your collection.",
      );

      const failedUrls = nonEmpty.filter((_, i) => results[i]?.status === "rejected");
      const errors = {} as Record<number, string | undefined>;
      let i = 0;
      for (const r of results) {
        if (r.status === "rejected") {
          const reason = r.reason instanceof APIError ? r.reason.message : undefined;
          if (!reason) continue;
          errors[i] = reason;
          i++;
        }
      }

      setState({
        urls: failedUrls,
        count: failedUrls.length,
        errors,
      });
    } catch (e) {
      setError("Something went wrong. Please try again.");
      captureException(e);
    }
    setLoading(false);
  };

  createEffect(
    on(
      () => state.count,
      (count) => {
        requestAnimationFrame(() => {
          const lastInput = formRef?.querySelectorAll("input")?.[count - 1] as HTMLInputElement | null;
          lastInput?.focus();
        });
      },
    ),
  );

  return (
    <>
      <a
        href="https://docs.storytell.ai/features/knowledge"
        // biome-ignore lint/a11y/noBlankTarget: <explanation>
        target="_blank"
        class="text-sm underline text-white flex items-center gap-1 mb-3 -mt-2"
      >
        <StIcon icon={TbQuestionMark} />
        <span>Learn more</span>
      </a>
      <Show when={props.type === "website"}>
        <p class="type-body-sm">You can add up to 10 links at a time.</p>
        <p class="type-body-sm mb-4">Got a list? Paste them all at once.</p>
      </Show>

      <form onSubmit={uploadPastedContent} ref={formRef}>
        <For each={Array.from({ length: state.count })}>
          {(_, index) => (
            <div class="mb-4 flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <input
                  disabled={loading()}
                  value={state.urls[index()]}
                  onInput={(e) => {
                    batch(() => {
                      const links = e.target.value.split(/[\s,;]+/).filter(Boolean);
                      if (links.length > 1) {
                        // Take first link for current input
                        setState("urls", index(), links[0] ?? "");
                        // Add remaining links as new entries up to max of 10
                        const remainingLinks = links.slice(1, 10 - state.count + 1);
                        if (remainingLinks.length > 0) {
                          setState("urls", (urls) => [...urls, ...remainingLinks]);
                          setState("count", (count) => Math.min(count + remainingLinks.length, 10));
                        }
                      } else {
                        setState("urls", index(), e.target.value);
                      }
                      setState("errors", index(), undefined);
                    });
                  }}
                  data-error={!!state.errors[index()]}
                  placeholder={props.type === "youtube" ? "Enter a YouTube link" : "Enter a website link"}
                  class={`
                  flex-auto
                  bg-transparent outline-none px-3 py-2 w-full border-2 border-indigo-700 dark:border-slate-600 hover:bg-indigo-900/30 dark:hover:bg-slate-400/5
                  border-dashed rounded-lg text-base leading-normal
                  data-[error=true]:ring-2 data-[error=true]:ring-danger
                `}
                />
                <Show when={state.count > 1}>
                  <div>
                    <IconCTA
                      icon={TbTrash}
                      accessibleLabel="Remove link"
                      data-test-id="remove-link"
                      onClick={() => {
                        batch(() => {
                          setState("errors", (errors) => {
                            const newErrors = {} as Record<number, string | undefined>;
                            for (const [i] of state.urls.entries()) {
                              if (i > index()) {
                                newErrors[i - 1] = errors[i];
                              } else {
                                newErrors[i] = errors[i];
                              }
                            }
                            return newErrors;
                          });
                          setState("urls", (urls) => urls.filter((_, i) => i !== index()));
                          setState("count", (count) => count - 1);
                        });
                      }}
                    />
                  </div>
                </Show>
              </div>
              <Show when={state.errors[index()]}>
                {(err) => <p class="text-on-background-danger type-body-sm">{err()}</p>}
              </Show>
            </div>
          )}
        </For>

        <Show when={showAddMore()}>
          <PrimaryCTA
            disabled={loading()}
            onClick={() =>
              setState((s) => ({
                urls: [...s.urls, ""],
                count: s.count + 1,
              }))
            }
            size="small"
            accessibleSuffix=" urls"
            label="Add more"
            data-test-id="add-more"
          />
        </Show>

        <div class="flex items-center justify-between gap-4 mt-4">
          <div>
            <Show when={error()}>
              <p class="text-red-300 text-sm line-clamp-4 whitespace-pre">{error()}</p>
            </Show>
          </div>

          <StButton size="lg" type="submit" class="w-auto" loading={loading()} loadingLabel="Uploading">
            Upload
          </StButton>
        </div>
      </form>
    </>
  );
};
