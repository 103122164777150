import { Extension, Node, nodeInputRule, nodePasteRule } from "@tiptap/core";
import { getTiptapSolidReactiveOwner } from "tiptap-solid";
import { useWire } from "@core/wire";
import { runWithOwner } from "solid-js";
import type { Node as ProseMirrorNode } from "prosemirror-model";
/**
 * This extension is responsible for parsing the mentions in the prompt when they
 * are in the form of @[collection or asset id]"Collection or Asset Name".
 *
 * The functionality for handling @mentioning as the user is typing and
 * showing autocomplete results is in Mentions.extension.ts
 */
export const CollectionMentionParser = Extension.create({
  name: "collectionMentionParser",
  priority: 102,
  onCreate() {
    this.editor.on("transaction", ({ editor }) => {
      // We need to collect all nodes first because we can't modify during traversal
      const nodesToReplace: { pos: number; len: number; id: string; name: string }[] = [];
      if (!editor.schema.nodes.mentionNode) return;

      const owner = getTiptapSolidReactiveOwner(editor);
      const wire = runWithOwner(owner, useWire);
      if (!wire) return;

      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === "text") {
          const match = node.text?.matchAll(/@\[(collection_[a-zA-Z0-9]{1,20}|asset_[a-zA-Z0-9]{1,20})\]"([^"]+)"/g);
          for (const m of match || []) {
            nodesToReplace.push({ pos: pos + m.index, len: m[0].length, id: m[1] || "", name: m[2] || "" });
          }

          return true;
        }
      });

      // If we found any nodes to replace, create a new transaction
      if (nodesToReplace.length > 0) {
        const tr = editor.state.tr;

        // Process replacements from end to start to maintain position accuracy
        for (const { pos, id, len, name } of nodesToReplace.reverse()) {
          const type = id.startsWith("collection_") ? "collection" : "asset";

          const data = type === "collection" ? wire.services.collections.getCollection(id) : undefined;

          tr.replaceWith(
            pos,
            pos + len,
            editor.schema.nodes.mentionNode.create({
              type,
              data: data ?? { id, originalFilename: name, label: name },
            }),
          );
        }

        editor.view.dispatch(tr);
      }
    });
  },
});
