import type { Setter } from "solid-js";
import type { ScreenType } from "typedefs/generic-exports";
import { useUIState } from "./UIState";

/**
 * Data attributes bound to documentElement to indicate the current screen type.
 */
export const SCREEN_TYPE: Record<ScreenType, string> = {
  mobile: "data-mobile-viewport",
  tablet: "data-tablet-viewport",
  desktop: "data-desktop-viewport",
} as const;

export const createIsMobileListener = (setter: Setter<boolean>) => {
  if (typeof globalThis.matchMedia === "function") {
    const isMobileQuery = globalThis.matchMedia("(max-width: 731px)");
    const isMobileListener = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setter(true);
        document.documentElement.setAttribute(SCREEN_TYPE.mobile, "true");
      } else {
        setter(false);
        document.documentElement.setAttribute(SCREEN_TYPE.mobile, "false");
      }
      document.body.classList.remove("overflow-hidden");
    };

    //  Ensure initial value is correct
    setter(isMobileQuery.matches);
    if (isMobileQuery.matches) {
      document.documentElement.setAttribute(SCREEN_TYPE.mobile, "true");
    } else {
      document.documentElement.setAttribute(SCREEN_TYPE.mobile, "false");
    }

    return { isMobileQuery, isMobileListener };
  }
  return {};
};

export const createIsTabletListener = (setter: Setter<boolean>) => {
  if (typeof globalThis.matchMedia === "function") {
    const isTabletQuery = globalThis.matchMedia("(min-width: 732px) and (max-width: 1279px)");
    const isTabletListener = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setter(true);
        document.documentElement.setAttribute(SCREEN_TYPE.tablet, "true");
      } else {
        setter(false);
        document.documentElement.setAttribute(SCREEN_TYPE.tablet, "false");
      }
      document.body.classList.remove("overflow-hidden");
    };

    //  Ensure initial value is correct
    setter(isTabletQuery.matches);
    if (isTabletQuery.matches) {
      document.documentElement.setAttribute(SCREEN_TYPE.tablet, "true");
    } else {
      document.documentElement.setAttribute(SCREEN_TYPE.tablet, "false");
    }

    return { isTabletQuery, isTabletListener };
  }
  return {};
};

export const createIsDesktopListener = (setter: Setter<boolean>) => {
  if (typeof globalThis.matchMedia === "function") {
    const isDesktopQuery = globalThis.matchMedia("(min-width: 1280px)");
    const isDesktopListener = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setter(true);
        document.documentElement.setAttribute(SCREEN_TYPE.desktop, "true");
      } else {
        setter(false);
        document.documentElement.setAttribute(SCREEN_TYPE.desktop, "false");
      }
      document.body.classList.remove("overflow-hidden");
    };

    //  Ensure initial value is correct
    setter(isDesktopQuery.matches);
    if (isDesktopQuery.matches) {
      document.documentElement.setAttribute(SCREEN_TYPE.desktop, "true");
    } else {
      document.documentElement.setAttribute(SCREEN_TYPE.desktop, "false");
    }

    return { isDesktopQuery, isDesktopListener };
  }
  return {};
};

export const closeAll = (omitOverlays?: boolean) => {
  const state = useUIState();
  const [leftDrawerOpen, setLeftDrawerOpen] = state.leftDrawer;
  const [rightDrawerOpen, setRightDrawerOpen] = state.rightDrawer;
  const [modalOpen, setModalOpen] = state.modal;
  const [contextMenuOpen, setContextMenuOpen] = state.collectionsContextMenu;
  const [accountContextMenu, setAccountContextMenu] = state.accountContextMenu;
  const [pseudoSelectMenu, setPseudoSelectMenu] = state.pseudoSelectMenu;
  const [helpContextMenu, setHelpContextMenu] = state.helpContextMenu;
  const [largeChatContextMenu, setLargeChatContextMenu] = state.collectionThreadsContextMenu;

  return {
    click: (event: Event) => {
      if (!omitOverlays) {
        if (leftDrawerOpen()) {
          setLeftDrawerOpen(false);
          event.stopImmediatePropagation();
        }
        if (rightDrawerOpen()) {
          setRightDrawerOpen(false);
          event.stopImmediatePropagation();
        }
        if (modalOpen()) {
          setModalOpen("");
          event.stopImmediatePropagation();
        }
      }
      if (contextMenuOpen()) {
        const target = event.target as HTMLElement;
        if (target) {
          if (!target.closest("[data-component='context-menu-item']")) {
            setContextMenuOpen("");
            event.stopImmediatePropagation();
          }
        }
      }
      if (accountContextMenu()) {
        const target = event.target as HTMLElement;
        if (target) {
          if (!target.closest("[data-component='context-menu-item']")) {
            setAccountContextMenu("");
            event.stopImmediatePropagation();
          }
        }
      }
      if (pseudoSelectMenu()) {
        const target = event.target as HTMLElement;
        if (target) {
          if (!target.closest("[data-component='context-menu-item']")) {
            setPseudoSelectMenu("");
            event.stopImmediatePropagation();
          }
        }
      }
      if (helpContextMenu()) {
        const target = event.target as HTMLElement;
        if (target) {
          if (!target.closest("[data-component='context-menu-item']")) {
            setHelpContextMenu("");
            event.stopImmediatePropagation();
          }
        }
      }
      if (largeChatContextMenu()) {
        const target = event.target as HTMLElement;
        if (target) {
          if (!target.closest("[data-component='context-menu-item']")) {
            setLargeChatContextMenu("");
            event.stopImmediatePropagation();
          }
        }
      }
    },
    key: (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (leftDrawerOpen()) {
          setLeftDrawerOpen(false);
        }
        if (rightDrawerOpen()) {
          setRightDrawerOpen(false);
        }
        if (modalOpen()) {
          setModalOpen("");
        }
      }
    },
  };
};
