/**
 * Parses a prompt string that can contain mentions of assets and collections into json parts.
 */
export const parsePromptTextMentions = (prompt: string) => {
  const mentionRegex = /@\[(asset|collection)_([a-z0-9]{20})\]"([^"]+)"/g;
  const segments: ({ type: "text"; text: string } | { type: "asset" | "collection"; id: string; name: string })[] = [];
  let lastIndex = 0;

  // Find all matches and build segments array
  for (const match of prompt.matchAll(mentionRegex)) {
    const [fullMatch, type, id, name] = match;

    // Add text before the match if any
    if (match.index > lastIndex) {
      segments.push({
        type: "text",
        text: prompt.slice(lastIndex, match.index),
      });
    }

    segments.push({
      type: type as "asset" | "collection",
      id: `${type}_${id}` || "",
      name: name || "",
    });

    lastIndex = match.index + fullMatch.length;
  }

  // Add remaining text after last match
  if (lastIndex < prompt.length) {
    segments.push({
      type: "text",
      text: prompt.slice(lastIndex),
    });
  }

  return segments;
};
