export const tailwindScreens = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
  "3xl": "1920px",
  "4xl": "2560px",
  "5xl": "3840px",
  landscape: "732px",
  tablet: "1024px",
  desktop: "1280px",
  "2k": "1921px",
  "4k": "2561px",
  "webflow-mobile": "767px",
  "webflow-tablet": "994px",
};
