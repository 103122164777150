import { type Component, createResource, ErrorBoundary, For, Match, Suspense, Switch } from "solid-js";
import { Spinner } from "@core/components/_original/Spinner";
import { captureException } from "@repo/observability";
import { SecondaryCTA } from "@core/components/cta/SecondaryCTA";
import { TbBulb, TbFile, TbRefresh } from "solid-icons/tb";
import styles from "./ImprovePromptModal.module.css";
import { useWire } from "@core/wire";
import { getRequestClient } from "@repo/client";
import { usePromptContext } from "@core/domains/chat/prompt/PromptContext";
import { HiSolidPaperAirplane } from "solid-icons/hi";
import { StIcon } from "@core/components/icons";
import { parsePromptTextMentions } from "@core/lib/parsePromptTextMentions";
import { getRootCollectionUIOverloads } from "@core/domains/collections/collections.helpers";
import { classNames } from "@core/lib/classNames";

export const ImprovePromptModal = (props: { prompt: string; onClose: () => void }) => {
  const wire = useWire();
  const { editor, typePrompt } = usePromptContext();
  const client = getRequestClient(wire.services.auth.token);

  const [res, { refetch }] = createResource(async () => {
    const res = await client.controlplane.PromptEnhancer({
      type: "auto",
      prompt: props.prompt,
    });
    return res.data;
  });

  return (
    <div class={styles["improve-prompt-modal"]}>
      <ErrorBoundary
        fallback={(err, reset) => {
          captureException(err);

          return (
            <>
              <p class={styles["improve-prompt-modal__title"]}>
                <StIcon size={20} icon={TbBulb} class={styles["improve-prompt-modal__title-icon"]} />
                <span class={styles["improve-prompt-modal__title-text"]}>Improve your prompt</span>
              </p>
              <div class={styles["improve-prompt-modal__error"]}>
                <p class={styles["improve-prompt-modal__error-title"]}>
                  We couldn't generate better prompt suggestions.
                </p>
                <p class={styles["improve-prompt-modal__error-subtitl"]}>
                  Click to retry. If the issue persists, please contact our support team.
                </p>

                <div>
                  <SecondaryCTA
                    accessiblePrefix="Click to "
                    icon={TbRefresh}
                    data-test-id="retry-improve-prompt-btn"
                    label="Retry"
                    onClick={() => {
                      refetch();
                      reset();
                    }}
                  />
                </div>
              </div>
            </>
          );
        }}
      >
        <Suspense
          fallback={
            <>
              <p class={styles["improve-prompt-modal__title"]}>
                <StIcon size={20} icon={TbBulb} class={styles["improve-prompt-modal__title-icon"]} />
                <span class={styles["improve-prompt-modal__title-text"]}>Improve your prompt</span>
              </p>
              <div class={styles["improve-prompt-modal__loading"]}>
                <Spinner class={styles["improve-prompt-modal__loading-spinner"]} />
                <p class={styles["improve-prompt-modal__loading-text"]}>Now generating 3 improved versions</p>
              </div>
            </>
          }
        >
          <p class={styles["improve-prompt-modal__title"]}>
            <StIcon size={20} icon={TbBulb} class={styles["improve-prompt-modal__title-icon"]} />
            <span class={styles["improve-prompt-modal__title-text"]}>Choose an improved prompt</span>
          </p>
          <div class={styles["improve-prompt-modal__created"]}>
            <For each={res()?.prompt_enhanced.content}>
              {(item, index) => (
                <div
                  onClick={() => {
                    props.onClose();
                    typePrompt(item);
                    editor()?.commands.focus();
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      props.onClose();
                      typePrompt(item);
                      editor()?.commands.focus();
                    }
                  }}
                  role="button"
                  tabIndex="0"
                  class={styles["improve-prompt-modal__created-prompt"]}
                >
                  <p class={styles["improve-prompt-modal__created-prompt-title"]}>
                    {res()?.prompt_enhanced.summary[index()]}
                  </p>
                  {/* <p class={styles["improve-prompt-modal__created-prompt-text"]}>{item}</p>
                   */}
                  <p
                    class={classNames(
                      "leading-normal font-medium text-black dark:text-white",
                      styles["improve-prompt-modal__created-prompt-text"],
                    )}
                  >
                    <For each={parsePromptTextMentions(item)}>
                      {(segment) => (
                        <Switch>
                          <Match when={segment.type === "text" && segment.text}>{(s) => <span>{s()}</span>}</Match>
                          <Match when={segment.type === "collection" && segment}>
                            {(s) => <PromptCollectionCTA id={s().id} name={s().name} />}
                          </Match>
                          <Match when={segment.type === "asset" && segment}>
                            {(s) => <PromptAssetCTA id={s().id} name={s().name} />}
                          </Match>
                        </Switch>
                      )}
                    </For>
                  </p>
                  <HiSolidPaperAirplane size={20} class={styles["improve-prompt-modal__created-prompt-icon"]} />
                </div>
              )}
            </For>

            <SecondaryCTA
              class={styles["improve-prompt-modal__created-regenerate"]}
              accessiblePrefix="Click to "
              icon={TbRefresh}
              data-test-id="retry-improve-prompt-btn"
              label="Regenerate"
              onClick={() => {
                refetch();
              }}
            />
          </div>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

const PromptAssetCTA: Component<{ id: string; name: string }> = (props) => {
  return (
    <span class="bg-secondary text-on-secondary relative top-0.5 px-2 rounded inline-flex items-center gap-1">
      <StIcon icon={TbFile} size={14} />
      <span>{props.name}</span>
    </span>
  );
};

export const PromptCollectionCTA: Component<{ id: string; name: string }> = (props) => {
  const wire = useWire();
  const collection = () => wire.services.collections.getCollection(props.id);
  const overrides = () => getRootCollectionUIOverloads(collection());
  return (
    <span class="bg-primary text-on-secondary relative top-0.5 px-2 rounded inline-flex items-center gap-1">
      <StIcon icon={overrides()?.icon!} size={14} />
      <span>{overrides()?.label || props.name}</span>
    </span>
  );
};
