import { HoverCard } from "@kobalte/core";
import { useLocation } from "@solidjs/router";
import { type Component, For } from "solid-js";
import { UpcomingFeature } from "@core/components/_original/UpcomingFeature";
import { Icon, IconName, IconsSize } from "@core/components/icons";
import { getPromptFromId } from "@core/marketing/useUseCasesData";
import { isHomePage } from "@core/lib/urls";
import { usePromptContext } from "../PromptContext";

const summaryPrompt = getPromptFromId("quick-summary");
const meetingSummaryPrompt = getPromptFromId("meeting-note-taker");
const swotPrompt = getPromptFromId("structured-framework-analysis-on-unstructured-content");
const whatAskPrompt = getPromptFromId("top-five-questions");
const eli5Prompt = getPromptFromId("-eli5");

export const QuestionLibraryQuestions = [
  {
    label: "Quick summary",
    createdBy: "Storytell",
    prompt: summaryPrompt,
  },
  {
    label: "Meeting Summary + AIs",
    createdBy: "Storytell",
    prompt: meetingSummaryPrompt,
  },
  {
    label: "SWOT analysis",
    createdBy: "Storytell",
    prompt: swotPrompt,
  },
  {
    label: "What should I ask?",
    createdBy: "Storytell",
    prompt: whatAskPrompt,
  },
  {
    label: "Explain it like I'm five (ELI5)",
    createdBy: "Storytell",
    prompt: eli5Prompt,
  },
];

export const ChatQuestionLibrary: Component = () => {
  const { editor, promptRef, setTransformationId } = usePromptContext();
  const location = useLocation();

  // Question library prompts should trigger campaigns from the home screen
  // so that users get a guided experience
  const isHomeScreen = () => isHomePage(location);

  return (
    <div class="max-w-full overflow-x-auto">
      <div class="grid grid-cols-3 gap-3 pt-2 pb-4 px-4 text-sm text-black dark:text-white w-full min-w-[700px]">
        <For each={QuestionLibraryQuestions}>
          {(item) => (
            <Question
              label={item.label}
              createdBy={item.createdBy}
              text={(isHomeScreen() ? item.prompt?.name : item.prompt?.prompt) || ""}
              onClick={() => {
                if (isHomeScreen()) setTransformationId(item.prompt?.id || "");
                editor()?.commands.setContent({
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: (isHomeScreen() ? item.prompt?.name : item.prompt?.prompt) || "",
                    },
                  ],
                });
                editor()?.commands.focus();
              }}
            />
          )}
        </For>
        <div class="w-full flex items-center gap-3">
          <UpcomingFeature
            mount={promptRef()}
            label="Question Library View all"
            triggerProps={{ class: "w-1/2 flex-shrink-0" }}
          >
            <button
              type="button"
              class="w-full flex items-center justify-center gap-3 bg-gray-200 dark:bg-slate-800 hover:bg-gray-300 dark:hover:bg-slate-700 border border-solid border-gray-300 dark:border-gray-600 rounded px-3 py-2"
            >
              <Icon name={IconName.Squares} size={IconsSize.Mini} class="-ml-4" />
              View all
            </button>
          </UpcomingFeature>
          <UpcomingFeature
            mount={promptRef()}
            label="Question Library New"
            triggerProps={{ class: "w-1/2 flex-shrink-0" }}
          >
            <button
              type="button"
              class="w-full flex items-center justify-center gap-3 bg-gray-200 dark:bg-slate-800 hover:bg-gray-300 dark:hover:bg-slate-700 border border-solid border-gray-300 dark:border-gray-600 rounded px-3 py-2"
            >
              <Icon name={IconName.Plus} size={IconsSize.Mini} class="-ml-4" />
              New
            </button>
          </UpcomingFeature>
        </div>
      </div>
    </div>
  );
};

const Question: Component<{
  label: string;
  createdBy: string;
  text: string;
  onClick: () => void;
}> = (props) => {
  const { promptRef } = usePromptContext();
  return (
    <HoverCard.Root placement="top" openDelay={250} closeDelay={250}>
      <HoverCard.Trigger
        as="button"
        onClick={props.onClick}
        class="w-full flex items-center justify-between bg-gray-200 dark:bg-slate-800 hover:bg-gray-300 dark:hover:bg-slate-700 border border-solid border-gray-300 dark:border-gray-600 rounded px-3 py-2"
      >
        {props.label}
        <Icon name={IconName.PaperAirplane} size={IconsSize.Mini} />
      </HoverCard.Trigger>
      <HoverCard.Portal mount={promptRef()}>
        <HoverCard.Content class="flex flex-col gap-2 max-w-96 bg-white dark:bg-slate-900 text-gray-800 dark:text-slate-100 rounded border dark:border-slate-600 shadow-lg p-4 animate-fade-out kb-expanded:animate-fade-in">
          <HoverCard.Arrow />
          <h3 class="text-base">{props.label}</h3>
          <div>
            <span class="text-xs px-2 py-1 rounded bg-violet-100 text-violet-800 dark:bg-violet-800 dark:text-violet-100">
              Created by {props.createdBy}
            </span>
          </div>

          <p class="text-sm">How we’re asking this question</p>

          <p class="p-2 bg-gray-100 dark:bg-slate-800 rounded max-h-48 overflow-y-auto text-sm max-w-full">
            {props.text}
          </p>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
