import { type ParentComponent, createContext, createEffect, on, runWithOwner, useContext } from "solid-js";
import type { SlashCommandItemType } from "./SlashCommand.types";
import { createStore, produce } from "solid-js/store";
import type { SlashCommandSuggestionProps } from "./SlashCommand.extension";
import { stAnalytics } from "@repo/analytics";
import { getTiptapSolidReactiveOwner } from "tiptap-solid";
import { getThreadEventProperties } from "@core/domains/analytics/useThreadEventProperties";
import { useWire } from "@core/wire";

const SlashMenuContext = createContext<{
  goNext: () => void;
  goPrevious: () => void;
  goParent: () => void;
  goChild: () => void;
  currentItem: () => SlashCommandItemType | undefined;
  isActive: (index: number, parents: number[]) => boolean;
  setActive: (index: number) => void;
  hasActiveChild: (index: number, parents: number[]) => boolean;
  onClose: () => void;
  rawProps: SlashCommandSuggestionProps;
  state: { active: number[] };
  selectActiveItem: () => void;
  selectItem: (index: number, parents: number[]) => void;
}>();

export const useSlashMenuContext = () => {
  const c = useContext(SlashMenuContext);
  if (!c) throw Error("No slash menu context");
  return c;
};

export const SlashMenuProvider: ParentComponent<SlashCommandSuggestionProps> = (props) => {
  const indexString = (n: number[]) => n.join("-");
  const initialState = () => ({
    active: [0],
  });
  const [state, setState] = createStore(initialState());

  const reset = () => {
    setState(initialState());
  };
  createEffect(
    on(
      () => props.items,
      () => reset(),
    ),
  );

  const isActive = (index: number, parents: number[]) => indexString([...parents, index]) === indexString(state.active);

  const hasActiveChild = (index: number, parents: number[]) => {
    const all = [...parents, index];
    return all.length < state.active.length && indexString(state.active).startsWith(indexString(all));
  };

  const getItem = (index: number, parents: number[]) => {
    const all = [...parents, index].reverse();
    let next = props.items;
    let item: SlashCommandItemType | undefined = undefined;
    while (all.length) {
      const index = all.pop();
      if (index === undefined) break;
      const i = next[index];
      if (i === undefined) break;
      item = i;
      if (!i.subItems) break;
      next = i.subItems;
    }
    return item;
  };

  const currentItem = () => {
    return getItem(state.active.at(-1)!, state.active.slice(0, -1));
  };

  const goNext = () => {
    const len = props.items.length;
    setState(
      produce((state) => {
        const { active } = state;
        const lastIndex = active.length - 1;
        if (active[lastIndex] === len - 1) {
          active[lastIndex] = 0;
        } else if (active[lastIndex] !== undefined) {
          active[lastIndex]++;
        }
      }),
    );
  };

  const goPrevious = () => {
    const len = props.items.length;
    setState(
      produce((state) => {
        const { active } = state;
        const lastIndex = active.length - 1;
        if (active[lastIndex] === 0) {
          active[lastIndex] = len - 1;
        } else if (active[lastIndex] !== undefined) {
          active[lastIndex]--;
        }
      }),
    );
  };

  const goParent = () => {
    if (state.active.length !== 1)
      setState(
        produce((state) => {
          state.active.pop();
        }),
      );
  };

  const goChild = () => {
    const item = currentItem();
    if (item?.subItems?.length) {
      setState("active", (state) => [...state, 0]);
    }
  };

  const setActive = (index: number) => {
    setState(
      "active",
      produce((active) => {
        active.pop();
        active.push(index);
      }),
    );
  };

  const onClose = () => {
    props.editor.commands.keyboardShortcut("Escape");
  };

  const selectActiveItem = () => {
    const item = currentItem();

    try {
      const owner = getTiptapSolidReactiveOwner(props.editor);
      const wire = runWithOwner(owner, useWire);
      if (wire)
        stAnalytics.track("prompt_slash_command_item_selected", {
          item: item?.label || "",
          ...getThreadEventProperties({
            workingContext: wire.services.auth.workingContext(),
            threadId: wire.services.threads.snapshot.context.threadId,
            threadMessages: wire.services.threads.messages(),
          }),
        });
    } catch (error) {}

    item?.onClick?.();
    props.editor.commands.focus();
    onClose();

    if (!item?.noClear) {
      props.editor.commands.deleteRange(props.range);
    }
  };
  const selectItem = (index: number, parents: number[]) => {
    const item = getItem(index, parents);

    try {
      const owner = getTiptapSolidReactiveOwner(props.editor);
      const wire = runWithOwner(owner, useWire);
      if (wire)
        stAnalytics.track("prompt_slash_command_item_selected", {
          item: item?.label || "",
          ...getThreadEventProperties({
            workingContext: wire.services.auth.workingContext(),
            threadId: wire.services.threads.snapshot.context.threadId,
            threadMessages: wire.services.threads.messages(),
          }),
        });
    } catch (error) {}

    item?.onClick?.();
    props.editor.commands.focus();
    onClose();
    if (!item?.noClear) {
      props.editor.commands.deleteRange(props.range);
    }
  };

  return (
    <SlashMenuContext.Provider
      value={{
        state,
        goNext,
        goPrevious,
        goParent,
        goChild,
        currentItem,
        isActive,
        setActive,
        hasActiveChild,
        onClose,
        rawProps: props,
        selectActiveItem,
        selectItem,
      }}
    >
      {props.children}
    </SlashMenuContext.Provider>
  );
};
