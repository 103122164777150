import { Motion, Presence } from "@repo/solid-motionone";
import {
  TbArrowBackUp,
  TbArrowForwardUp,
  TbAt,
  TbBolt,
  TbBrandMeta,
  TbBrandOpenai,
  TbBrandOpenSource,
  TbPaperclip,
  TbSlash,
} from "solid-icons/tb";
import { type Component, type JSX, type ParentComponent, Show } from "solid-js";
import { KeyboardShortcutPill } from "@core/components/_original/KeyboardShortcutPill";
import { StButton } from "@core/components/_original/StButton";
import { Tooltip, TooltipTrigger } from "@core/components/_original/Tooltip";
import { StBrandAnthropic, StBrandDeepSeek, type StIconComponent } from "@core/components/icons";
import { useWire } from "@core/wire";
import { usePromptContext } from "../PromptContext";
import { ChatPromptImproving } from "./ChatPromptImproving";
import { StDropdown } from "@core/components/_original/StDropdown";
import { modelDisplayName, modelVendor } from "@repo/client";
import { StBrandGemini } from "@core/components/icons/StBrandGemini";
import { A } from "@solidjs/router";
import { useUIState } from "@core/ui/UIState";

export const ChatBottomBar: Component = () => {
  const { focused, editor, setShowUploadModal, promptRef } = usePromptContext();
  const ui = useUIState();
  const wire = useWire();

  const [selectedModel, setSelectedModel] = ui.llmModelOverride;

  return (
    <Presence exitBeforeEnter>
      <Show when={focused()}>
        <Motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            height: "2.25rem",
          }}
          exit={{ opacity: 0, height: 0 }}
          class="relative overflow-y-hidden"
        >
          <div class="flex items-center justify-between gap-2 absolute bottom-0 left-0 right-0 text-2xs text-gray-600 dark:text-gray-300 rounded-b px-2 py-2">
            <div class="flex items-center gap-2">
              <ToolbarButton
                label="Slash command"
                onClick={() => editor()?.commands.invokeSlashCommand()}
                icon={TbSlash}
              />
              <ToolbarButton
                label="Upload files"
                disabled={wire.services.auth.isConnecting()}
                onClick={() => {
                  setShowUploadModal(true);
                }}
                icon={TbPaperclip}
              />
              <ToolbarButton
                label="Specify a Collection or an Asset"
                onClick={() => editor()?.commands.invokeMention()}
                icon={TbAt}
              />
              <ToolbarButton
                label="Previous prompt"
                tooltip={
                  <p>
                    Previous prompt{" "}
                    <KeyboardShortcutPill
                      keys={["ArrowUp"]}
                      class="ml-1 px-1.5 py-0 border text-gray-100 dark:text-slate-900 bg-slate-800 dark:bg-slate-300 border-slate-500 dark:border-slate-400 text-sm leading-6 font-black"
                    />
                  </p>
                }
                onClick={() => {
                  wire.services.inputHistory.getPrevious(editor());
                  editor()?.commands.focus("end");
                }}
                icon={TbArrowBackUp}
                disabled={wire.services.inputHistory.isFirst()}
              />
              <ToolbarButton
                label="Next prompt"
                tooltip={
                  <span>
                    Next prompt{" "}
                    <KeyboardShortcutPill
                      keys={["ArrowDown"]}
                      class="ml-1 px-1.5 py-0 border text-gray-100 dark:text-slate-900 bg-slate-800 dark:bg-slate-300 border-slate-500 dark:border-slate-400 text-sm leading-6 font-black"
                    />
                  </span>
                }
                onClick={() => {
                  wire.services.inputHistory.getNext(editor());
                  editor()?.commands.focus("end");
                }}
                icon={TbArrowForwardUp}
                disabled={wire.services.inputHistory.isLast()}
              />
              {/* <ToolbarButton
              label="Mention someone"
              onClick={() => editor()?.commands.invokeMention()}
              icon={TbAt}
            /> */}

              {/* <UpcomingFeature
              label="Prompt Format Text Button"
              mount={promptRef()}
            >
              <ToolbarButton label="Format Text" icon={TbTypography} />
            </UpcomingFeature> */}

              <ChatPromptImproving />
            </div>

            <Show
              when={wire.services.auth.isVerified()}
              fallback={
                <Tooltip
                  content={
                    <p>
                      <A href="/auth/signup" class="underline">
                        Sign up or log in
                      </A>{" "}
                      to pick specific LLMs to respond
                    </p>
                  }
                  theme="dark"
                  placement="top"
                >
                  <TooltipTrigger as="span">
                    <StButton icon={TbBolt} size="sm">
                      {modelDisplayName(selectedModel() || "DynamicLLM")}
                    </StButton>
                  </TooltipTrigger>
                </Tooltip>
              }
            >
              <StDropdown
                theme="dark"
                opts={{ placement: "bottom-end" }}
                trigger={{ as: "span" }}
                content={{ class: "z-[1000]" }}
                items={[
                  {
                    kind: "item",
                    content: (
                      <span class="flex flex-col gap-1">
                        <span class="leading-none font-medium">DynamicLLM</span>
                        <span class="leading-none text-xs text-blue-200">Let Storytell pick the best model</span>
                      </span>
                    ),
                    icon: TbBolt,
                    props: {
                      onClick: () => setSelectedModel(""),
                      class: `h-12${selectedModel() === "" ? " bg-blue-800" : ""}`,
                    },
                  },
                  {
                    kind: "menu",
                    trigger: {
                      content: "Gemini",
                      icon: StBrandGemini,
                      props: {
                        class: modelVendor(selectedModel() || "") === "google" ? "bg-blue-900" : "",
                      },
                    },
                    items: [
                      {
                        kind: "item",
                        content: "Gemini 2.0 Pro",
                        icon: StBrandGemini,
                        props: {
                          onClick: () => setSelectedModel("gemini-2.0-pro-exp-02-05"),
                          class: selectedModel() === "gemini-2.0-pro-exp-02-05" ? "bg-blue-800" : "",
                        },
                      },
                      {
                        kind: "item",
                        content: "Gemini 2.0 Flash",
                        icon: StBrandGemini,
                        props: {
                          onClick: () => setSelectedModel("gemini-2.0-flash-001"),
                          class: selectedModel() === "gemini-2.0-flash-001" ? "bg-blue-800" : "",
                        },
                      },
                    ],
                  },
                  {
                    kind: "menu",
                    trigger: {
                      content: "OpenAI",
                      icon: TbBrandOpenai,
                      props: {
                        class: modelVendor(selectedModel() || "") === "openai" ? "bg-blue-900" : "",
                      },
                    },
                    items: [
                      {
                        kind: "item",
                        content: "GPT-4o",
                        icon: TbBrandOpenai,
                        props: {
                          onClick: () => setSelectedModel("gpt-4o"),
                          class: selectedModel() === "gpt-4o" ? "bg-blue-800" : "",
                        },
                      },
                      {
                        kind: "item",
                        content: "GPT-4o Mini",
                        icon: TbBrandOpenai,
                        props: {
                          onClick: () => setSelectedModel("gpt-4o-mini"),
                          class: selectedModel() === "gpt-4o-mini" ? "bg-blue-800" : "",
                        },
                      },
                      {
                        kind: "item",
                        content: "O1",
                        icon: TbBrandOpenai,
                        props: {
                          onClick: () => setSelectedModel("o1"),
                          class: selectedModel() === "o1" ? "bg-blue-800" : "",
                        },
                      },
                      {
                        kind: "item",
                        content: "O3 Mini",
                        icon: TbBrandOpenai,
                        props: {
                          onClick: () => setSelectedModel("o3-mini"),
                          class: selectedModel() === "o3-mini" ? "bg-blue-800" : "",
                        },
                      },
                    ],
                  },
                  {
                    kind: "menu",
                    trigger: {
                      content: "Anthropic",
                      icon: StBrandAnthropic,
                      props: {
                        class: modelVendor(selectedModel() || "") === "anthropic" ? "bg-blue-900" : "",
                      },
                    },
                    items: [
                      {
                        kind: "item",
                        content: "Claude 3.7 Sonnet",
                        icon: StBrandAnthropic,
                        props: {
                          onClick: () => setSelectedModel("claude-3-7-sonnet-latest"),
                          class: selectedModel() === "claude-3-7-sonnet-latest" ? "bg-blue-800" : "",
                        },
                      },
                      {
                        kind: "item",
                        content: "Claude 3.5 Sonnet",
                        icon: StBrandAnthropic,
                        props: {
                          onClick: () => setSelectedModel("claude-3-5-sonnet-20241022"),
                          class: selectedModel() === "claude-3-5-sonnet-20241022" ? "bg-blue-800" : "",
                        },
                      },
                    ],
                  },
                  {
                    kind: "menu",
                    trigger: {
                      content: "Open Source",
                      icon: TbBrandOpenSource,
                      props: {
                        class:
                          modelVendor(selectedModel() || "") === "deepseek" ||
                          modelVendor(selectedModel() || "") === "meta"
                            ? "bg-blue-800"
                            : "",
                      },
                    },
                    items: [
                      {
                        kind: "item",
                        content: "DeepSeek R1 Distill Llama 70B",
                        icon: StBrandDeepSeek,
                        props: {
                          onClick: () => setSelectedModel("deepseek-r1-distill-llama-70b"),
                          class: selectedModel() === "deepseek-r1-distill-llama-70b" ? "bg-blue-800" : "",
                        },
                      },
                      {
                        kind: "item",
                        content: "Llama 3.3 70B",
                        icon: TbBrandMeta,
                        props: {
                          onClick: () => setSelectedModel("llama-3.3-70b-versatile"),
                          class: selectedModel() === "llama-3.3-70b-versatile" ? "bg-blue-800" : "",
                        },
                      },
                    ],
                  },
                ]}
                mount={promptRef()}
              >
                <StButton icon={TbBolt} size="sm">
                  {modelDisplayName(selectedModel() || "DynamicLLM")}
                </StButton>
              </StDropdown>
            </Show>
          </div>
        </Motion.div>
      </Show>
    </Presence>
  );
};

const ToolbarButton: ParentComponent<{
  label: string;
  tooltip?: JSX.Element;
  onClick?: () => void;
  icon: StIconComponent;
  disabled?: boolean;
}> = (props) => {
  return (
    <Tooltip theme="invert" placement="top" content={props.tooltip ?? <p>{props.label}</p>}>
      <TooltipTrigger as="span">
        <StButton size="sm" onClick={props.onClick} icon={props.icon} disabled={props.disabled} label={props.label} />
      </TooltipTrigger>
    </Tooltip>
  );
};
